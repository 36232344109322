import React, {
    createElement,
    createRef,
    Dispatch,
    Fragment,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
    MutableRefObject,
} from 'react';
import './Dialog.scss';

let scrollbarWidth: number | undefined;

export function getScrollbarWidth() {
    if (!scrollbarWidth) {
        const scrollDiv = document.createElement('div');
        Object.assign(scrollDiv.style, {
            width: '100px',
            height: '100px',
            position: 'absolute',
            left: '-999em',
            overflow: 'scroll',
        });
        document.body.insertBefore(scrollDiv, document.body.firstChild);
        scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }
    return scrollbarWidth;
}

let ids: number[] = [];

let _originalBodyPadding: string | null;

interface DialogProps {
    bodyClassName?: string;
    children?: ReactNode;
    className?: string;
    closing?: boolean;
    dialog?: JSX.Element;
    footer?: ReactNode;
    onClose: () => void;
    onClosing?: () => boolean | undefined | void;
    requestClose?: Promise<void>;
    size?: 'sm' | 'md' | 'lg' | 'max' | 'full';
    showCloseControl?: boolean;
    tagName?: string;
    title?: ReactNode;
}

export interface DialogOptions extends Partial<DialogProps> {}

interface ConfirmOptions extends DialogOptions {
    okText?: ReactNode;
    cancelText?: ReactNode;
}

export interface PromptOptions extends DialogOptions {
    okText?: ReactNode;
    label?: string | JSX.Element;
    inputType?: string;
}

export function prompt(body: ReactNode, options: PromptOptions) {
    const id = Math.random();

    return new Promise<string>((resolve) => {
        const input = createRef<HTMLInputElement>();

        let requestClose: () => void;

        const handleOkClick = function (e: React.MouseEvent<HTMLButtonElement>) {
            e.preventDefault();
            if (!input.current) {
                return;
            }
            requestClose();
            resolve(input.current.value);
        };

        const onClose = function () {
            const cb = options.onClose;
            delete options.onClose;
            if (cb) {
                cb();
            }
            cleanup();
        };

        const props = {
            title: 'Prompt',
            ...options,
            requestClose: new Promise<void>((r) => (requestClose = r)),
            children: (
                <div className="mb-2 form-group">
                    {options.label && <label>{options.label}</label>}
                    {body && <div className="mb-2">{body}</div>}
                    <input type={options.inputType || 'text'} className="form-control" ref={input} />
                </div>
            ),
            onClose,
            footer: (
                <>
                    <button type="submit" className="btn btn-primary" onClick={handleOkClick}>
                        {options.okText || 'Ok'}
                    </button>
                </>
            ),
        };

        const dialog = createDialog(id, props);
        const cleanup = dialog();

        setTimeout(() => input.current && input.current.focus(), 300);
    });
}

interface ConfirmRenderProps {
    ok: () => void;
    cancel: () => void;
}

type RenderDelegate = (props: ConfirmRenderProps) => ReactNode;

export function confirm(body: ReactNode | RenderDelegate, options?: ConfirmOptions, idRef?: MutableRefObject<number>) {
    const id = Math.random();
    if (idRef) {
        idRef.current = id;
    }
    return new Promise<boolean>((resolve) => {
        let requestClose: () => void;

        const handleOkClick = (e?: React.MouseEvent<HTMLElement>) => {
            if (e) {
                e.preventDefault();
            }
            requestClose();
            resolve(true);
        };

        const onClosing = function () {
            if (options && options.onClosing) {
                options.onClosing();
            }
            requestClose();
            resolve(false);
        };

        const onClose = function () {
            if (options) {
                const cb = options.onClose;
                delete options.onClose;
                if (cb) {
                    cb();
                }
            }
            cleanup();
        };

        let renderer = body as RenderDelegate;
        if (typeof body !== 'function') {
            renderer = () => body;
        }

        const props = {
            title: 'Confirm',
            ...options,
            footer: (
                <>
                    <button type="submit" className="modal-ok-button" id="btnOk" onClick={handleOkClick}>
                        {(options && options.okText) || 'Ok'}
                    </button>

                    {options && options.cancelText && (
                        <button onClick={onClosing} type="button" className="btn btn-outline">
                            {options.cancelText}
                        </button>
                    )}
                </>
            ),
            children: <div className="form-group">{renderer({ ok: handleOkClick, cancel: onClosing })}</div>,
            onClosing,
            onClose,
            requestClose: new Promise<void>((r) => (requestClose = r)),
        };

        const dialog = createDialog(id, props);
        const cleanup = dialog();
    });
}

export function createDialog(id: number, props: DialogOptions) {
    return function () {
        if (!_setDialogs) {
            throw new Error('_setDialogs is not initialized');
        }

        _setDialogs((dialogs) => {
            const modified = { ...dialogs, [id]: props };
            return modified;
        });

        return function cleanup() {
            _setDialogs((dialogs) => {
                const modified = { ...dialogs, [id]: { ...props, closing: true } };
                return modified;
            });
        };
    };
}

export function Dialog(props: DialogOptions) {
    const id = useRef(Math.random());

    const initializer = useRef(createDialog(id.current, props));
    useEffect(() => initializer.current(), []);

    useEffect(() =>
        _setDialogs((dialogs) => {
            const modified = { ...dialogs, [id.current]: props };
            return modified;
        })
    );
    return <Fragment />;
}

type Status = 'open' | 'closing' | 'closed';

function DialogView(props: DialogOptions) {
    const [display, setDisplay] = useState('none');
    const [className, setClassName] = useState('');
    const id = useRef(Math.random());

    const { onClosing, onClose, requestClose } = props;

    const [status, setStatus] = useState<Status>('open');
    const handleClosed = useCallback(
        function () {
            setStatus((currentStatus) => {
                if (currentStatus === 'closed') {
                    return currentStatus;
                }

                if (onClose) {
                    setTimeout(onClose);
                }
                return 'closed';
            });
        },
        [onClose, setStatus]
    );

    const handleClosing = useCallback(
        function () {
            setStatus((currentStatus) => {
                if (currentStatus !== 'open') {
                    return currentStatus;
                }

                if (onClosing && onClosing() === false) {
                    return currentStatus;
                }

                requestAnimationFrame(function () {
                    setClassName('');
                });
                setTimeout(handleClosed, 300);

                document.body.classList.remove('modal-open');
                document.body.style.paddingRight = _originalBodyPadding || '';

                return 'closing';
            });
        },
        [handleClosed, onClosing, setStatus]
    );

    const handleKeyDown = useCallback(
        function (e: KeyboardEvent) {
            if (e.keyCode === 27 && ids[ids.length - 1] === id.current) {
                handleClosing();
            }
        },
        [id, handleClosing]
    );

    useEffect(
        function () {
            if (requestClose) {
                requestClose.then(handleClosing);
            }
        },
        [requestClose, handleClosing]
    );

    useEffect(
        function () {
            if (props.closing) {
                handleClosing();
            }
        },
        [props.closing, handleClosing]
    );

    useEffect(
        function () {
            document.addEventListener('keydown', handleKeyDown);
            return function () {
                document.removeEventListener('keydown', handleKeyDown);
            };
        },
        [handleKeyDown]
    );

    useEffect(function () {
        document.body.classList.add('modal-open');
        _originalBodyPadding = document.body.style.paddingRight;
        if (document.body.scrollHeight > document.body.clientHeight) {
            document.body.style.paddingRight = getScrollbarWidth() + 'px';
        }

        const currentId = id.current;
        ids.push(currentId);
        requestAnimationFrame(function () {
            if (ids.includes(currentId)) {
                setDisplay('block');
            }
        });

        return function () {
            ids = ids.filter((x) => x !== currentId);
            if (ids.length === 0) {
                document.body.classList.remove('modal-open');
                document.body.style.paddingRight = _originalBodyPadding || '';
            }
        };
    }, []);

    useEffect(
        function () {
            if (display === 'block') {
                setClassName('show');
            }
        },
        [display, setClassName]
    );

    const { title, footer, size } = props;

    if (status === 'closed') {
        return null;
    }

    const content = (
        <div
            style={{ display }}
            className={`modal fade ${props.className || ''} ${className}`}
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
        >
            <div className={`modal-dialog modal-${size} d-flex`} role="document">
                <div className="w-full modal-content flex-fill">
                    {(title || props.showCloseControl) && (
                        <div className="modal-header">
                            <h1 className="modal-title">{title}</h1>
                            {props.showCloseControl && (
                                <button type="button" className="close" aria-label="Close" onClick={handleClosing}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            )}
                        </div>
                    )}
                    <div className={`modal-body ${props.bodyClassName || ''}`}>{props.children}</div>
                    {footer && <div className="modal-footer">{footer}</div>}
                </div>
            </div>
        </div>
    );

    return createElement(props.tagName || 'form', null, content);
}

interface Dialogs {
    [key: number]: DialogOptions;
}
let _setDialogs: Dispatch<SetStateAction<Dialogs>>;

export function DialogContainer() {
    const [dialogs, setDialogs] = useState<Dialogs>({});
    _setDialogs = setDialogs;

    return (
        <div id="dialog-container">
            {Object.entries(dialogs).map(([key, props]) => (
                <DialogView key={key} {...props} />
            ))}
        </div>
    );
}
