/* eslint-disable react-hooks/exhaustive-deps */
import { AccessTypes } from '../GraphQL.User';
import { Navigate, Outlet } from 'react-router';

import { Loading } from '../shared/Loading';
import { useState, useEffect } from 'react';
import { useLayoutContext } from '../layout/Layout';

interface ProtectedRouteProps {
    allowedAccessTypes: AccessTypes[];
}

export default function ProtectedRoute(props: ProtectedRouteProps): JSX.Element {
    const { model, update } = useLayoutContext();
    const [element, setElement] = useState(<Loading />);

    useEffect(() => {
        const updateElement = (accessType: AccessTypes) => {
            if (accessType === AccessTypes.FullAccess || props.allowedAccessTypes.includes(accessType))
                setElement(<Outlet />);
            else {
                setElement(<Navigate replace to={"/"} />);
            }
        };

        if (model.accessType === AccessTypes.Unauthenticated) { //force update and recheck to account for delay in access updating.
            update().then((response) => {
                const accessType = response?.accessType ?? AccessTypes.Unauthenticated;
                updateElement(accessType);
            })
        } else {
            updateElement(model.accessType)
        }
    }, []);

    return element
}