import { Fragment, useEffect } from 'react';
import { SessionData } from './extractSessionData';

declare global {
    interface Window {
        _uxa: unknown[];
    }
}

interface ContentSquareProps {
    sessionInfo: SessionData;
}

function trackDynamicVariable(key: string, value: number | string | undefined) {
    if (!value) return;
    const _uxa = window._uxa ?? (window._uxa = []);
    _uxa.push(['trackDynamicVariable', { key, value }]);
}

export function ContentSquare(props: ContentSquareProps) {
    const { sessionInfo } = props;
    const { webVisitKey, loanApplicationKey, customerKey, visitorKey, loanKey, state, loanStatus, productType, pendingReasons, isReturningCustomer} = sessionInfo;

    useEffect(() => trackDynamicVariable('WebVisitKey', webVisitKey), [webVisitKey]);
    useEffect(() => trackDynamicVariable('LoanApplicationKey', loanApplicationKey), [loanApplicationKey]);
    useEffect(() => trackDynamicVariable('VisitorKey', visitorKey), [visitorKey]);
    useEffect(() => trackDynamicVariable('CustomerKey', customerKey), [customerKey]);
    useEffect(() => trackDynamicVariable('IsReturningCustomer', isReturningCustomer ? 1 : 0), [isReturningCustomer]);
    useEffect(() => trackDynamicVariable('LoanKey', loanKey), [loanKey]);
    useEffect(() => trackDynamicVariable('State', state), [state]);
    useEffect(() => trackDynamicVariable('LoanStatus', loanStatus), [loanStatus]);
    useEffect(() => trackDynamicVariable('ProductType', productType), [productType]);
    useEffect(() => trackDynamicVariable('PendingReasons', pendingReasons), [pendingReasons]);


    return <Fragment />;
}
