import { Fragment, useEffect } from 'react';
import { SessionData } from './extractSessionData';

declare global {
    interface Window {
        dataLayer?: unknown[];
    }
}

interface GoogleTagManagerDataLayerProps {
    sessionInfo: SessionData;
}

function insertSessionData(key: string, value: number | string | undefined) {
    if (!value) return;
    const dataLayer = (window.dataLayer ??= []);
    dataLayer.push({ ['sessionData' + key]: value });
}

export function GoogleTagManagerDataLayer(props: GoogleTagManagerDataLayerProps) {
    const { sessionInfo } = props;
    const { loanApplicationKey, loanKey, customerKey, visitorKey } = sessionInfo;

    useEffect(() => insertSessionData('VisitorKey', visitorKey), [visitorKey]);
    useEffect(() => insertSessionData('CustomerKey', customerKey), [customerKey]);
    useEffect(() => insertSessionData('LoanApplicationKey', loanApplicationKey), [loanApplicationKey]);
    useEffect(() => insertSessionData('LoanKey', loanKey), [loanKey]);

    return <Fragment />;
}
