import { Dispatch, Fragment, SetStateAction, useEffect, ReactNode, useState } from 'react';
import { isProduction } from './isProduction';

let defaultTools: Record<string, ReactNode> = {};
let _setTools: Dispatch<SetStateAction<Record<string, ReactNode>>>;

export function TestTools({ children, id }: { children: ReactNode; id: string }) {
    useEffect(() => {
        if (!isProduction) {
            if (_setTools) {
                _setTools((prev) => ({ ...prev, [id]: children }));
            } else {
                defaultTools[id] = children;
            }
        }
        return () => {
            if (_setTools) {
                _setTools((prev) => ({ ...prev, [id]: undefined }));
            } else {
                delete defaultTools[id];
            }
        };
    }, [id, children]);

    return <Fragment />;
}

export function TestToolsContainer() {
    const [tools, setTools] = useState<Record<string, ReactNode>>(defaultTools);
    _setTools = setTools;

    return <>{!isProduction && Object.entries(tools).map(([key, tools]) => <Fragment key={key}>{tools}</Fragment>)}</>;
}
