import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { getMultipleRoutes, Routes } from '../Routes';
import { Brands } from '../Brand';
import { AccessTypes } from '../GraphQL.User';
import ProtectedRoute from '../middleware/ProtectedRoute';

const LazyLogin = lazy(() => import('./login/LoginSection'));
const LazyMessageAuthentication = lazy(() => import('./login/MessageAuthenticationSection'));
const LazyResetPassword = lazy(() => import('./login/reset-password/ResetPasswordSection'));
const LazyRetrievePassword = lazy(() => import('./login/retrieve-password/RetrievePasswordSection'));

const LazyAccountSetup = lazy(() => import('./account-setup/AccountSetupSection'));
const LazyAccountSummary = lazy(() => import('./account-summary/AccountSummarySection'));
const LazyEditAccountSecurity = lazy(() => import('./my-account/edit-account-security/EditAccountSecuritySection'));
const LazyEditContactInfo = lazy(() => import('./my-account/edit-contact-info/EditContactInfoSection'));
const LazyEditBanking = lazy(() => import('./my-account/edit-banking/EditBankingSection'));
const LazyAdditionalActionSection = lazy(() => import('./my-account/edit-banking/AdditionalActionSection'));
const LazyConfirmBankingInformationSection = lazy(() => import('./my-account/edit-banking/confirm-banking-information/ConfirmBankingInformationSection'));
const LazyDocumentsSection = lazy(() => import('./my-account/edit-banking/documents/EditBankingDocumentsSection'));
const LazyConfirmBankingInformationSectionSRC = lazy(() => import('./my-account/edit-banking/confirm-banking-information/ConfirmBankingInformationSection.SpeedyCash'));
const LazyEditSpouse = lazy(() => import('./my-account/edit-spouse/EditSpouseSection'));

export function getUserRoutes(brand: Brands) {
    return (
        <>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer]} />}>
                <Route path={Routes.User.AccountSummary} element={<LazyAccountSummary />} />
                <Route path={Routes.User.EditAccountSecurity} element={<LazyEditAccountSecurity />} />
                <Route path={Routes.User.EditBanking.AdditionalAction} element={<LazyAdditionalActionSection />} />
                <Route path={Routes.User.EditContactInfo} element={<LazyEditContactInfo />} />
                <Route path={Routes.User.EditBanking.ConfirmBankingInformation}
                    element={
                        brand === Brands.SpeedyCash
                            ? <LazyConfirmBankingInformationSectionSRC />
                            : <LazyConfirmBankingInformationSection />
                    }
                />
                <Route path={Routes.User.EditBanking.Documents} element={<LazyDocumentsSection />} />
                <Route path={Routes.User.EditSpouse} element={<LazyEditSpouse />} />
                {getMultipleRoutes(
                    [Routes.User.EditBanking.WithKey, Routes.User.EditBanking.Root],
                    <LazyEditBanking />
                )}
            </Route>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer, AccessTypes.ChangePassword]} />}>
                <Route path={Routes.User.AccountSetup} element={<LazyAccountSetup />} />
            </Route>
            <Route path={Routes.User.Login} element={<LazyLogin />} />
            <Route path={Routes.User.MessageAuthentication} element={<LazyMessageAuthentication />} />
            <Route path={Routes.User.ResetPassword} element={<LazyResetPassword />} />
            <Route path={Routes.User.RetrievePassword} element={<LazyRetrievePassword />} />
        </>
    )
}