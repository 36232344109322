declare global {
    interface PromiseConstructor {
        delay: (delayInMilliseconds: number) => Promise<void>;
    }

    interface FileData {
        name: string;
        content: string;
        contentType: string;
    }

    interface Curo {
        addEventListener(type: string, callback: (data: unknown) => void): () => void;
        postMessage(type: string, data: unknown): void;
        saveFileAs(file: FileData): void;
        triggerEvent(type: string, data: unknown): void;
    }

    interface Fastlink {
        open(fastlinkObject: any, containerName: string): void;
    }

    interface Spreedly {
        init(environmentKey: string, options: any): void;
        on(event: string, action: any): void;
        setFieldType(field: string, type: string): void;
        setNumberFormat(format: string): void;
        setStyle(field: string, css: string): void;
        tokenizeCreditCard(cardFields: any): void;
    }

    interface Window {
        curo?: Curo;
        fastlink?: Fastlink;
        Spreedly?: Spreedly;
        ReactNativeWebView?: {
            postMessage(data: string): void;
        };
    }
}

export function polyfill() {
    if (!Promise.delay) {
        Promise.delay = function (delayInMilliseconds: number) {
            return new Promise<void>((resolve, reject): void => {
                setTimeout(() => {
                    resolve();
                }, delayInMilliseconds);
            });
        };
    }
    if (!Object.fromEntries) {
        Object.fromEntries = function (entries: any): any {
            const result: Record<string, unknown> = {};
            for (const [key, value] of entries) {
                result[key] = value;
            }
            return result;
        };
    }
}
