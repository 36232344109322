import type { GraphQLError } from 'graphql/error/GraphQLError';

export type ModelState<T = string> = Record<T extends string ? string : keyof T, string>;

function lowerCaseFirst(value: string) {
    if (!value) return value;
    return value.substring(0, 1).toLowerCase() + value.substring(1);
}

export const sliceModelState = (modelState: ModelState | undefined, prefix: string) => {
    const keys = modelState && Object.keys(modelState).filter((x) => x.indexOf(prefix) === 0);
    let result: ModelState | undefined;
    if (modelState && keys && keys.length > 0) {
        result = {};
        for (const key of keys) {
            result[key.substr(prefix.length)] = modelState[key];
        }
    }
    return result;
};

export const getModelState = <T = string>(errors: ReadonlyArray<GraphQLError> | undefined, prefixToRemove: string = '') => {
    if (!errors?.length) return undefined;
    const _validationErrors = errors.filter((x) => x.message.indexOf('ValidationError') > -1);
    let errorData = _validationErrors?.[0]?.extensions?.data as ModelState<T> | undefined;
    if (!errorData) return undefined;
    errorData = Object.fromEntries(
        Object.entries(errorData).map(([key, value]) => [lowerCaseFirst(key.replace(prefixToRemove, '')), Array.isArray(value) ? value.join('\n') : value])
    ) as ModelState<T>;
    return errorData;
};
