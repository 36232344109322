import { equalsIgnoringCase } from './StringHelper';

export function isHtmlButton(elm: Element | null | undefined): elm is HTMLButtonElement {
    return elm?.tagName === 'BUTTON';
}

export function isHtmlAnchor(elm: Element | null | undefined): elm is HTMLAnchorElement {
    return elm?.tagName === 'A';
}

export function isHtmlSelectElement(elm: Element | null | undefined): elm is HTMLSelectElement {
    return elm?.tagName === 'SELECT';
}

export function isHtmlInputElement(elm: Element | null | undefined): elm is HTMLInputElement {
    return elm?.tagName === 'INPUT';
}

export function isHtmlFormElement(elm: Element | null | undefined): elm is HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement {
    return !!elm && /input|textarea|select/i.test(elm.tagName);
}

export function setTextBox(element: Element, value: string | number | boolean | File) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        element.tagName === 'INPUT'
            ? window.HTMLInputElement.prototype
            : element.tagName === 'TEXTAREA'
            ? window.HTMLTextAreaElement.prototype
            : undefined,
        'value'
    )?.set;

    let files: File[] | undefined;

    if (isHtmlInputElement(element) && isFile(value)) {
        if (!isAcceptableFile(value, element.accept)) return;
        files = [value];
        Object.defineProperties(element, {
            files: {
                get() {
                    return files;
                },
            },
        });
    } else {
        nativeInputValueSetter?.call(element, value);
    }
    element.dispatchEvent(new Event('input', { bubbles: true, files } as any));
    element.dispatchEvent(new Event('change', { bubbles: true, files } as any));
}

function isFile(value: unknown): value is File {
    const file = value as File;
    return !!file && typeof file === 'object' && 'size' in file && 'name' in file && 'type' in file;
}

function isAcceptableFile(file: File, accept: string) {
    if (!accept) return true;
    const wildcards = ['audio/*', 'image/*', 'video/*'];
    return accept.split(',').some((acceptToken) => {
        if (acceptToken.startsWith('.')) {
            // tokens starting with a dot represent a file extension
            return file.name.endsWith(acceptToken);
        } else if (wildcards.includes(acceptToken)) {
            return file.type.startsWith(acceptToken.substring(0, acceptToken.length - 1));
        }
        return file.type === acceptToken;
    });
}

export function setRadioButton(element: HTMLInputElement) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked')?.set;
    nativeInputValueSetter?.call(element, true);
    element.dispatchEvent(new Event('click', { bubbles: true }));
    element.dispatchEvent(new Event('change', { bubbles: true }));
}

export function setCheckbox(element: HTMLInputElement) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked')?.set;
    nativeInputValueSetter?.call(element, true);
    element.dispatchEvent(new Event('click', { bubbles: true }));
    element.dispatchEvent(new Event('change', { bubbles: true }));
}

export function setSelectValue(element: Element, valueOrText: string | number) {
    let value = valueOrText.toString();
    if (isHtmlSelectElement(element)) {
        const options = Array.from(element.options);
        if (!options.some((x) => x.value === value)) {
            value =
                (options.find((x) => equalsIgnoringCase(x.value, value)) ?? options.find((x) => equalsIgnoringCase(x.text, value)))
                    ?.value ?? value;
        }
    }

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value')?.set;
    nativeInputValueSetter?.call(element, value);
    element.dispatchEvent(new Event('change', { bubbles: true }));
}
