import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { polyfill } from './polyfills';
import { GraphQLProvider } from './shared/useGraphQL';

polyfill();

const { location } = window;
if (location.protocol !== 'https:' && location.hostname.indexOf('.') > -1) {
    location.replace(location.toString().replace(/^http:/i, 'https:'));
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('root not found');
const root = createRoot(rootElement);

/*
TODO: <React.StrictMode /> causes an error in v18
    Cannot read properties of null (reading 'insertBefore')
        at substituteLinkWithInlineStyle (inline.js:137:1)
        at inline.js:244:1
*/

root.render(
    <GraphQLProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </GraphQLProvider>
);
