// This code was generated by a tool. Do not edit manually.
import type { ExecutionResult } from "graphql/execution";

export function createRequestInit(input: AbortSignal | RequestInit | undefined): RequestInit {
  if (!input) return {};
  const signal = input as AbortSignal;
  if ("aborted" in input) return { signal };
  return input;
}

export abstract class BaseClient {
  public path = "/graphql";
  public fetch = (path: RequestInfo, init?: RequestInit) => fetch(path, init);
  public handleError?: (result: ExecutionResult<unknown>, query: string, variables: unknown) => Promise<void>;
  

  public JSON = {
    parse: (text: string) => JSON.parse(text),
    stringify: (obj: {}) => JSON.stringify(obj),
  };
  
  public redirect(path: string) {
    window.location.replace(path);
  }

  public async request<T>(query: string, variables: {}, init: RequestInit, name: string): Promise<ExecutionResult<T>> {
    const response = await this.fetch(this.path + "?" + name, {
      method: "post",
      credentials: "include",
      body: this.JSON.stringify({ query, variables, operationName: name }),
      ...init,
      headers: { "Content-Type": "application/json", Accept: "application/json", ...init.headers },
    });
    const text = await response.text();
    const result = this.JSON.parse(text) as ExecutionResult<T>;
    if (result?.extensions?.redirect) {
      this.redirect(String(result.extensions.redirect));
    }
    if (result?.errors?.length) {
      await this.handleError?.(result, query, variables);
    }
    return result;
  }

  
}
