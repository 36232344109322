import { addDays, format, parse } from 'date-fns';
export { addDays, isSameDay } from 'date-fns';

const LONG_DATE_FORMAT = 'MMMM d, yyyy';
const SHORT_TIME_FORMAT = 'h:mm a';

const DAY_OF_WEEK = 'EEEE';
const MONTH_AND_YEAR = 'MM/yyyy';
const DAY_OF_WEEK_WITH_MONTH_AND_DAY = 'EEEE, MMMM do';

type DateLike = string | number | Date | undefined;

const CURRENT_YEAR = new Date().getFullYear();
const MAX_YEAR = CURRENT_YEAR + 100;
const MIN_YEAR = CURRENT_YEAR - 100;

export const parseDate = (value: DateLike): Date | undefined => {
    let result: Date | undefined;
    if (value) {
        if (value instanceof Date) {
            result = value;
        } else {
            result = new Date(value);
        }
    }
    if (!result || !result.getDate()) {
        return undefined;
    }
    return result;
};

export const INTERNATIONAL_FORMAT = 'yyyy-MM-dd';
const isInternationalFormat = RegExp.prototype.test.bind(/\d{4}-\d+-\d+/);

export const isDstObserved = (value?: Date) => {
    if (!value) {
        return null;
    }
    var jan = new Date(value.getFullYear(), 0, 1);
    var jul = new Date(value.getFullYear(), 6, 1);
    var offset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

    return value.getTimezoneOffset() < offset;
};

export const isAM = (date: Date) => {
    return date.getHours() < 12;
};

export const dstAbbr = (value?: Date, isCanada?: boolean) => {
    return isCanada ? (isDstObserved(value) ? 'EDT' : 'EST') : isDstObserved(value) ? 'CDT' : 'CST';
};

export const parseDateExact = (value: string, dateFormat: string): Date | undefined => {
    if (!value) {
        return undefined;
    }

    let newFormat = dateFormat;
    if (dateFormat !== INTERNATIONAL_FORMAT && isInternationalFormat(value)) {
        newFormat = INTERNATIONAL_FORMAT;
    }

    const result = parse(value, newFormat, new Date());

    return result.getDate() && result.getFullYear() <= MAX_YEAR && result.getFullYear() >= MIN_YEAR ? result : undefined;
};

export const getWeekOfMonth = (datestr: DateLike): string => {
    const date = parseDate(datestr);
    const day = date && date.getDate();
    if (!day) {
        return '';
    }
    const num = Math.ceil(day / 7);
    return num > 4 ? 'Last' : num.toString();
};

export function formatMonthAndYear(value: DateLike, defaultValue?: string): string | undefined {
    return formatDate(value, MONTH_AND_YEAR, defaultValue);
}

export function formatDayOfWeekWithMonthAndDay(value: DateLike, defaultValue?: string): string | undefined {
    return formatDate(value, DAY_OF_WEEK_WITH_MONTH_AND_DAY, defaultValue);
}

export function formatLongDate(value: DateLike, defaultValue?: string): string | undefined {
    return formatDate(value, LONG_DATE_FORMAT, defaultValue);
}

export function formatShortTime(value: DateLike, defaultValue?: string): string | undefined {
    return formatDate(value, SHORT_TIME_FORMAT, defaultValue);
}

export function formatDayOfWeek(value: DateLike, defaultValue?: string): string | undefined {
    return formatDate(value, DAY_OF_WEEK, defaultValue);
}

export function formatDate(value: DateLike, formatting: string): string | undefined;
export function formatDate(value: DateLike, formatting: string, defaultValue: string | undefined): string;
export function formatDate(value: DateLike, formatting: string, defaultValue?: string): string | undefined {
    const date = parseDate(value);
    if (!date) return defaultValue;
    return format(date, formatting);
}

export const addDaysUntilFuture = (date: DateLike, days: number): Date | undefined => {
    const now = new Date();
    let result = parseDate(date);
    while (result && result < now) {
        result = addDays(result, days);
    }
    return result;
};
