import { getDaysInMonth } from 'date-fns';
import { OtherEarningsType, PayCycle } from '../GraphQL.LoanApplication';
import { formatDate, addDays } from './DateHelper';
import { KeyValuePair } from './KeyValuePair';

const NOW = new Date();
const SUNDAY = addDays(NOW, -NOW.getDay());

export const YEARS_LIST_ITEMS = Array(60)
    .fill(NOW.getFullYear())
    .map((year, i) => ({ key: (year - i).toString(), value: year - i }));
YEARS_LIST_ITEMS.unshift({ key: 'Year', value: NaN });

export const DOB_YEARS_LIST_ITEMS = Array(100)
    .fill(NOW.getFullYear() - 18)
    .map((year, i) => ({ key: (year - i).toString(), value: year - i }));
DOB_YEARS_LIST_ITEMS.unshift({ key: 'Year', value: NaN });

export const EXPIRY_YEARS_LIST_ITEMS = Array(10)
    .fill(NOW.getFullYear())
    .map((year, i) => ({ key: (year + i).toString(), value: year + i }));
EXPIRY_YEARS_LIST_ITEMS.unshift({ key: 'Year', value: NaN });

export const MONTHS_LIST_ITEMS = Array(12)
    .fill(1)
    .map((_, index) => ({
        key: formatDate(new Date(NOW.getFullYear(), index, 1), 'MMMM', ''),
        value: index + 1,
    }));
MONTHS_LIST_ITEMS.unshift({ key: 'Month', value: NaN });

export const WEEKDAYS_LIST_ITEMS: Readonly<KeyValuePair<string, number>[]> = Array.from({ length: 8 }, (_, index) =>
    index === 0
        ? { key: '', value: NaN }
        : {
            key: formatDate(addDays(SUNDAY, index - 1), 'EEEE', ''),
            value: index - 1,
        }
);

export const UNIT_TYPE_LIST_ITEMS: Readonly<KeyValuePair<string, string>[]> = [
    { value: '', key: '' },
    { value: 'APT', key: 'Apartment' },
    { value: 'BLDG', key: 'Building' },
    { value: 'DEPT', key: 'Department' },
    { value: 'LOT', key: 'Lot' },
    { value: 'RM', key: 'Room' },
    { value: 'SPC', key: 'Space' },
    { value: 'STE', key: 'Suite' },
    { value: 'TRLR', key: 'Trailer' },
    { value: 'UNIT', key: 'Unit' },
];

export const PROVINCE_LIST_ITEMS: Readonly<KeyValuePair<string, string>[]> = [
    { value: '', key: '' },
    { value: 'AB', key: 'Alberta' },
    { value: 'BC', key: 'British Columbia' },
    { value: 'MB', key: 'Manitoba' },
    { value: 'NB', key: 'New Brunswick' },
    { value: 'NL', key: 'Newfoundland and Labrador' },
    { value: 'NT', key: 'Northwest Territories' },
    { value: 'NS', key: 'Nova Scotia' },
    { value: 'NU', key: 'Nunavut' },
    { value: 'ON', key: 'Ontario' },
    { value: 'PE', key: 'Prince Edward Island' },
    { value: 'QC', key: 'Quebec' },
    { value: 'SK', key: 'Saskatchewan' },
    { value: 'YT', key: 'Yukon Territory' },
];

export const STATE_LIST_ITEMS: Readonly<KeyValuePair<string, string>[]> = [
    { key: '', value: '' },
    { value: 'AL', key: 'Alabama' },
    { value: 'AK', key: 'Alaska' },
    { value: 'AZ', key: 'Arizona' },
    { value: 'AR', key: 'Arkansas' },
    { value: 'CA', key: 'California' },
    { value: 'CO', key: 'Colorado' },
    { value: 'CT', key: 'Connecticut' },
    { value: 'DE', key: 'Delaware' },
    { value: 'DC', key: 'District Of Columbia' },
    { value: 'FL', key: 'Florida' },
    { value: 'GA', key: 'Georgia' },
    { value: 'HI', key: 'Hawaii' },
    { value: 'ID', key: 'Idaho' },
    { value: 'IL', key: 'Illinois' },
    { value: 'IN', key: 'Indiana' },
    { value: 'IA', key: 'Iowa' },
    { value: 'KS', key: 'Kansas' },
    { value: 'KY', key: 'Kentucky' },
    { value: 'LA', key: 'Louisiana' },
    { value: 'ME', key: 'Maine' },
    { value: 'MD', key: 'Maryland' },
    { value: 'MA', key: 'Massachusetts' },
    { value: 'MI', key: 'Michigan' },
    { value: 'MN', key: 'Minnesota' },
    { value: 'MS', key: 'Mississippi' },
    { value: 'MO', key: 'Missouri' },
    { value: 'MT', key: 'Montana' },
    { value: 'NE', key: 'Nebraska' },
    { value: 'NV', key: 'Nevada' },
    { value: 'NH', key: 'New Hampshire' },
    { value: 'NJ', key: 'New Jersey' },
    { value: 'NM', key: 'New Mexico' },
    { value: 'NY', key: 'New York' },
    { value: 'NC', key: 'North Carolina' },
    { value: 'ND', key: 'North Dakota' },
    { value: 'OH', key: 'Ohio' },
    { value: 'OK', key: 'Oklahoma' },
    { value: 'OR', key: 'Oregon' },
    { value: 'PA', key: 'Pennsylvania' },
    { value: 'RI', key: 'Rhode Island' },
    { value: 'SC', key: 'South Carolina' },
    { value: 'SD', key: 'South Dakota' },
    { value: 'TN', key: 'Tennessee' },
    { value: 'TX', key: 'Texas' },
    { value: 'UT', key: 'Utah' },
    { value: 'VT', key: 'Vermont' },
    { value: 'VA', key: 'Virginia' },
    { value: 'WA', key: 'Washington' },
    { value: 'WV', key: 'West Virginia' },
    { value: 'WI', key: 'Wisconsin' },
    { value: 'WY', key: 'Wyoming' },
];

export function getDaysInMonthList(month: number, year?: number): Readonly<KeyValuePair<string, number>[]> {
    const length = (year ? getDaysInMonth(new Date(year, month)) : getDaysInMonth(month)) + 1;
    return Array.from({ length }, (_, index) =>
        index === 0
            ? { key: 'Day', value: NaN }
            : {
                key: index.toString(),
                value: index,
            }
    );
}

export function getPastYearsList(numberOfYears: number): Readonly<KeyValuePair<string, number>[]> {
    const year = NOW.getFullYear() + 1;
    const pastYearsList = Array.from({ length: numberOfYears + 1 }, (_, i) =>
        i === 0 ? { key: '', value: NaN } : { key: (year - i).toString(), value: year - i }
    );
    return pastYearsList;
}

export function getOtherEarningsTypeList(): Readonly<KeyValuePair<string, OtherEarningsType>[]> {
    return Object.keys(OtherEarningsType).map(key => {
        return { key: key.toString(), value: OtherEarningsType[key as keyof typeof OtherEarningsType] }
    });
}

export const PAY_CYCLE_LIST_ITEMS: Readonly<KeyValuePair<string, PayCycle>[]> = [
    { value: PayCycle.None, key: '' },
    { value: PayCycle.BiWeekly, key: 'BiWeekly' },
    { value: PayCycle.Weekly, key: 'Weekly' },
    { value: PayCycle.Monthly, key: 'Monthly' },
    { value: PayCycle.TwiceMonthly, key: 'Twice a month' },
];