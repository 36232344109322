const isIsoDate = RegExp.prototype.test.bind(/^\d{4}(?:-\d\d){2}T\d\d(?::\d\d){2}(?:\.\d+)?(?:Z|[+-]\d\d:\d\d)?$/);

export const normalizeJson = (key: string, value: {}) => {
    if (typeof value === 'string' && isIsoDate(value)) {
        let date = new Date(value);
        if (!date.getTime() || date.getFullYear() <= 1900) {
            console.warn('Invalid Date: ' + value);
            date = new Date(1899, 0, 1);
        }
        return date;
    }

    if (value === null) {
        return undefined;
    }

    return value;
};

export function parseJson<T = unknown>(text: string) {
    const result = JSON.parse(text, normalizeJson);
    return result as T;
}
