import { Fragment, useEffect } from 'react';
import { SessionData } from './extractSessionData';

function raiseEvent(name: string, value: number | undefined) {
    if (!value) return;
    window?.curo?.postMessage(`curo:${name}-changed`, { value });
}

export function HostValues({ sessionInfo }: { sessionInfo: SessionData }) {
    const { visitorKey, loanApplicationKey, customerKey } = sessionInfo;

    useEffect(() => raiseEvent('loan-application-key', loanApplicationKey), [loanApplicationKey]);
    useEffect(() => raiseEvent('visitor-key', visitorKey), [visitorKey]);
    useEffect(() => raiseEvent('customer-key', customerKey), [customerKey]);

    return <Fragment />;
}
