import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { getMultipleRoutes, Routes } from '../Routes';
import { Brands } from '../Brand';
import ProtectedRoute from '../middleware/ProtectedRoute';
import { AccessTypes } from '../GraphQL.User';

const LazyContactUs = lazy(() => import('./email/ContactUsSection'));
const LazyContactUsAdAstra = lazy(() => import('../communication/email/ContactUsSection.AdAstra'));
const LazyUnsubscribe = lazy(() => import('./unsubscribe/UnsubscribeSection'));
const LazyUnsubscribeConfirmation = lazy(() => import('./unsubscribe/UnsubscribeConfirmationSection'));

const LazyHistory = lazy(() => import('./history/CommunicationHistorySection'));
const LazyHistoryAdAstra = lazy(() => import('./history/CommunicationHistorySection.AdAstra'));
const LazyPreferences = lazy(() => import('./preferences/CommunicationPreferencesSection'));
const LazyConsent = lazy(() => import('./consents/CommunicationConsentsSection'));

export function getCommunicationRoutes(brand: Brands) {
    return (
        <>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer]} />}>
                <Route path={Routes.Communication.Consents} element={<LazyConsent />} />
                <Route path={Routes.Communication.History} element={brand === Brands.AdAstra ? <LazyHistoryAdAstra /> : <LazyHistory />} />
                {getMultipleRoutes(
                    [Routes.Communication.Preferences.WithMessageId, Routes.Communication.Preferences.Root],
                    <LazyPreferences />
                )}
            </Route>
            {getMultipleRoutes(
                [Routes.Communication.Email.WithKey, Routes.Communication.Email.Root],
                brand === Brands.AdAstra ? <LazyContactUsAdAstra /> : <LazyContactUs />
            )}
            {getMultipleRoutes(
                [Routes.Communication.Unsubscribe.WithMessageId, Routes.Communication.Unsubscribe.Root],
                <LazyUnsubscribe />
            )}
            <Route path={Routes.Communication.UnsubscribeConfirmation} element={<LazyUnsubscribeConfirmation />} />
        </>
    );
}
