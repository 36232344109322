import { client } from '../shared/Client';

async function keepAlive() {
    const data = await client.postToJson<{ redirect?: string }>('/Utilities/KeepAlive.json');
    if (data.redirect) {
        window.location.href = data.redirect;
    }
}

let tmr: number | undefined;

export function scheduleKeepAlive() {
    window.clearInterval(tmr);
    tmr = window.setInterval(keepAlive, 60e3);
}
scheduleKeepAlive();
