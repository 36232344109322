import { AccessTypes, LayoutViewModel } from '../GraphQL.Default';

export const DEFAULT_LAYOUT_VIEW_MODEL: LayoutViewModel = {
    accessType: AccessTypes.Unauthenticated,
    customerServicePhone: '',
    greetingFirstName: '',
    isLoggedIn: false,
    isRapidCash: false,
    loanCount: 0,
    persistedMessage: undefined,
    secureSiteAddress: '/',
    sessionInfo: '',
    showApplyNowButton: true,
    siteAddress: '/',
    requiresPasswordReset: false
};
