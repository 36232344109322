export function equalsIgnoringCase(text: string | undefined, other: string | undefined) {
    return (text ?? '').localeCompare(other ?? '', undefined, { sensitivity: 'base' }) === 0;
}

export function getOrdinal(num: string | number) {
    if (!num) {
        return '';
    }
    const numstr = typeof num === 'string' ? num : num.toString();
    const numStrLast = /\d$/.exec(numstr);
    const lastTwo = /\d\d$/.exec(numstr);
    if (!numStrLast) {
        return '';
    }

    if (lastTwo) {
        switch (lastTwo[0]) {
            case '11':
            case '12':
            case '13':
                return 'ᵗʰ';
            default:
                break;
        }
    }

    switch (numStrLast[0]) {
        case '1':
            return 'ˢᵗ';
        case '2':
            return 'ⁿᵈ';
        case '3':
            return 'ʳᵈ';
        default:
            return 'ᵗʰ';
    }
}

export const addOrdinal = (num: string | number) => `${num}${getOrdinal(num)}`;

export const cleanNumeric = (value: string) => value.replace(/[^\d.]/g, '');

export const formatNumber = (num: number | undefined, decimalPlaces: number = 2) =>
    (num ?? 0).toFixed(decimalPlaces).replace(/\B(?=(\d{3})+\b)/g, ',');


// Formats to two decimal Avoids two issues that .toFixed() and formatCurrency() presented:
// 1) Prevents rounding up when more than two decimal numbers are entered
// 2) Ensures that the second decimal point always has a '0' rather than nothing.
// 3) Ensures that the full number is formatted with commas
export const formatStringNumber = (num: string | undefined) => {
    const splitValue = num?.split('.');
    let finalValue = '';
    var left = splitValue?.[0];
    if (left) {
        left = cleanNumeric(left).split(/(?=(?:\d{3})+$)/).join(",");
        var right = splitValue?.[1] ?? '';
        right = right.concat('00').slice(0, 2);
        finalValue = `${left}.${right}`;
    }
    return finalValue;
}

export function formatCurrency(value: number | undefined, decimalPlaces?: number): string | undefined;
export function formatCurrency(value: number | undefined, decimalPlaces: number, defaultValue: string): string;
export function formatCurrency(value: number | undefined, decimalPlaces: number = 2, defaultValue?: string): string | undefined {
    if (value === undefined) return defaultValue;
    const result = formatNumber(value, decimalPlaces);
    return result ? `$${result}` : defaultValue;
}

export const formatPhoneNumber = (value: string | undefined) => {
    let clean = (value || '').replace(/\D+/g, '');
    if (clean.length === 11 && clean[0] === '1') {
        clean = clean.substring(1);
    }
    if (clean.length !== 10) {
        return undefined;
    }
    return `(${clean.substring(0, 3)}) ${clean.substring(3, 6)}-${clean.substring(6)}`;
};

export const formatPhoneNumberWithDashes = (value: string | undefined) => {
    let clean = (value || '').replace(/\D+/g, '');
    if (clean.length === 11 && clean[0] === '1') {
        clean = clean.substring(1);
    }
    if (clean.length !== 10) {
        return undefined;
    }
    return `1-${clean.substring(0, 3)}-${clean.substring(3, 6)}-${clean.substring(6)}`;
};

export const last = (value: string | undefined, length: number) => {
    value = value || '';
    if (length > value.length) {
        return value;
    }
    return value.substring(value.length - length);
};

export const maskCard = (last4: string | undefined) => {
    last4 = last4 || '';
    if (last4 === '') {
        return last4;
    }
    return `############${last4}`;
}

export const formatCardBrand = (value: string | undefined) => {
    const cardBrand = value?.replace(/[^a-zA-Z]/g, '').toLowerCase();
    switch (cardBrand) {
        case 'mastercard':
            return 'Mastercard';
        case 'visa':
            return 'Visa';
        case 'americanexpress':
            return 'American Express';
        case 'discover':
            return 'Discover';
        default:
            return value;
    }
};

const alphanumeric = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
export function getRandomString(length: number, alphabet = alphanumeric) {
    return Array.from({ length }, () => alphabet[Math.floor(Math.random() * alphabet.length)]).join('');
}
