import { useEffect } from 'react';

export function useFavicon(url: string) {
    useEffect(
        function() {
            const head = document.head || (document.getElementsByTagName('head')[0] as HTMLHeadElement);
            let favicon = document.querySelector('head link[rel="shortcut icon"]') as HTMLLinkElement | null;
            if (favicon) {
                favicon.remove();
            }
            favicon = document.createElement('link');
            favicon.rel = 'shortcut icon';
            favicon.href = url;
            head.appendChild(favicon);
        },
        [url]
    );
}
