import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { BaseClient } from '../GraphQL.BaseClient';
import { GraphQLClient } from '../GraphQL.Default';
import { scheduleKeepAlive } from '../layout/useKeepAlive';
import { parseJson } from './JsonHelper';
import { getModelState } from './ModelState';
import { triggerFetchEvent } from '../shared/Events';
import { getRandomString } from './StringHelper';
import { convertToBase64, sign, getCertificateFingerprint } from './authentication/Crypt';

type ConcreteType<T> = { new (): T } & T;

export const GraphQLContext = createContext((Client: ConcreteType<BaseClient>) => new GraphQLClient() as BaseClient);
const defaultClient = new GraphQLClient();

export function createClientCache() {
    return new Map<typeof BaseClient, BaseClient>();
}

export function GraphQLProvider({ children }: { children: ReactNode; accessor?: (type: BaseClient) => void }) {
    const [clients] = useState(() => new Map<typeof BaseClient, BaseClient>());
    const provider = useCallback(
        (Client: ConcreteType<BaseClient>) => {
            const cacheKey = Client ?? GraphQLClient;
            let client = clients.get(cacheKey);
            if (!client) {
                client = Client ? new Client() : (new GraphQLClient() as BaseClient);
                setupGraphQLClient(client);
                clients.set(cacheKey, client);
            }
            return client;
        },
        [clients]
    );
    return <GraphQLContext.Provider value={provider}>{children}</GraphQLContext.Provider>;
}

function setupGraphQLClient(client: BaseClient) {
    client.JSON.parse = parseJson;
    client.handleError = async (result) => {
        if (!result.errors?.length) return;
        console.error(result.errors);

        if (result.errors?.some((x) => (x.extensions?.data as Record<string, unknown>)?.clearUserProfile)) {
            window.location.replace('/User/Logout');
            console.log('errors', result.errors);
            return;
        }

        // check if the error was logged server side
        const errorId = result.errors?.map((x) => (x?.extensions?.data as Record<string, unknown>)?.errorId).filter(Boolean)?.[0];
        if (errorId) {
            sessionStorage.setItem('errorId', String(errorId));
            return;
        }

        const validationErrors = getModelState(result.errors);
        // check if it's a validation messages that will be shown in the ui
        if (validationErrors && Object.keys(validationErrors).length) return;

        const message = window.location.pathname + ': GraphQL Errors';
        const data = result.errors.map((x, i) => ({ key: `message[${i}]`, value: x.message }));
        defaultClient.log(message, data);
    };
    client.fetch = async (path, init) => {
        const body = init?.body ?? '';
        let timeDifference = 0;

        const fingerprint = getCertificateFingerprint();

        if (fingerprint && typeof body === 'string') {
            const timestamp = new Date(Date.now() + timeDifference).toISOString();
            const nonce = getRandomString(10);
            const payload = body + timestamp + nonce;
            const signature = await sign(payload);

            const authorization = convertToBase64(['sha256', signature, fingerprint, timestamp, nonce].join('\n'));
            init = {
                ...init,
                headers: {
                    ...init?.headers,
                    Authorization: `x-cert ${authorization}`,
                },
            };
        }

        const response = await fetch(path, init);
        scheduleKeepAlive();
        triggerFetchEvent(path, init, response);

        return response;
    };
}

export function useGraphQL<T extends new () => InstanceType<T> & BaseClient = typeof GraphQLClient>(Client?: T): InstanceType<T> {
    const clients = useContext(GraphQLContext);
    return clients((Client ?? GraphQLClient) as unknown as ConcreteType<BaseClient>) as InstanceType<T>;
}
