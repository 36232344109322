import { useEffect } from 'react';
import { parseJson } from './JsonHelper';

const all = new Map<string, Set<Function>>();

export function addEventHandler(name: string, callback: Function) {
    let handlers = all.get(name);
    if (!handlers) {
        all.set(name, (handlers = new Set()));
    }
    handlers.add(callback);
    return () => removeEventHandler(name, callback);
}

export function removeEventHandler(name: string, callback: Function) {
    const handlers = all.get(name);
    if (handlers) {
        handlers.delete(callback);
    }
}

export function triggerEvent(name: string, ...args: unknown[]) {
    const handlers = all.get(name);
    if (!handlers) return;
    handlers.forEach((handler) => handler(...args));
}

export function useEvent(name: string, handler: Function) {
    useEffect(() => addEventHandler(name, handler), [name, handler]);
}

export function waitForEvent(name: string, predicate: (...args: any[]) => boolean, signal?: AbortSignal, describe?: string) {
    return new Promise<void>((resolve, reject) => {
        const aborted = () => {
            signal?.removeEventListener('abort', aborted);
            reject(`waitForEvent('${name}', "${describe ?? '...'}") cancelled`);
        };
        signal?.addEventListener('abort', aborted);

        const handler: (...args: unknown[]) => void = (...args: unknown[]) => {
            const match = predicate(...args);
            if (!match) return;
            signal?.removeEventListener('abort', aborted);
            removeEventHandler(name, handler);
            resolve();
        };
        addEventHandler(name, handler);
    });
}

export function triggerFetchEvent(path: RequestInfo, init: RequestInit | undefined, response: Response) {
    triggerEvent('fetch', createHttpInfo(path, init, response));
}

export async function waitForFetch(pathname: string | RegExp = '', signal?: AbortSignal) {
    let _http: HttpInfo;
    await waitForEvent(
        'fetch',
        (http: HttpInfo) => {
            const path = http.request.url;
            const matches = typeof pathname === 'string' ? path.includes(pathname) : pathname.test(path);
            if (matches) {
                _http = http;
                const response = http.response;
                // overriden to allow multiple reads
                const text = response.text.bind(response);
                let _text: Promise<string> | undefined;
                response.text = () => _text ?? (_text = text());
                response.json = async () => parseJson(await response.text());
            }
            return matches;
        },
        signal,
        pathname?.toString()
    );

    return _http!;
}

export type HttpInfo = ReturnType<typeof createHttpInfo>;
function createHttpInfo(path: RequestInfo, init: RequestInit | undefined, response: Response) {
    const request = new Request(path, init);
    return {
        request,
        response,
        requestJson<T = any>() {
            const json = init?.body?.toString() ?? '';
            try {
                return parseJson<T>(json);
            } catch (x) {
                console.error(`Unable to parse JSON`, json || 'Empty json data', x);
                throw x;
            }
        },
        async responseJson<T = any>() {
            return (await response.json()) as T;
        },
    };
}
