// This code was generated by a tool. Do not edit manually.
import { BaseClient, createRequestInit } from './GraphQL.BaseClient';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Char: string;
}

export enum AccessTypes {
  Application = 'APPLICATION',
  AutoPay = 'AUTO_PAY',
  BuyUp = 'BUY_UP',
  ChangeDueDate = 'CHANGE_DUE_DATE',
  ChangeFundingMethod = 'CHANGE_FUNDING_METHOD',
  ChangePassword = 'CHANGE_PASSWORD',
  Customer = 'CUSTOMER',
  Documents = 'DOCUMENTS',
  FullAccess = 'FULL_ACCESS',
  Messages = 'MESSAGES',
  None = 'NONE',
  Payment = 'PAYMENT',
  PaymentReauthorization = 'PAYMENT_REAUTHORIZATION',
  QuickUpload = 'QUICK_UPLOAD',
  Refinance = 'REFINANCE',
  TellerAccess = 'TELLER_ACCESS',
  Unauthenticated = 'UNAUTHENTICATED'
}

export interface AddressModelInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  unitNumber?: InputMaybe<Scalars['String']>;
  unitType?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface ContactSupportViewModel {
  contactKey?: Maybe<Scalars['String']>;
  errorId?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorUrl?: Maybe<Scalars['String']>;
  exceptionText?: Maybe<Scalars['String']>;
  isInternal: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
  referenceNumber?: Maybe<Scalars['String']>;
  resourceKey?: Maybe<Scalars['String']>;
}

export interface CustomerServiceInfoModel {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}

export interface DecisionLogicFailModel {
  canUseElectronicPaystub: Scalars['Boolean'];
  canUsePictureUtility: Scalars['Boolean'];
  incomeNeedsVerified: Scalars['Boolean'];
  loanApplicationKey: Scalars['Int'];
  pictureUtilityDownloadUrl?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface DecisionLogicPassGetResponse {
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface DecisionLogicVerificationModel {
  bankId: Scalars['Int'];
  decisionLogicBankListItems?: Maybe<Array<Maybe<StringStringKeyValuePairGraphType>>>;
  redirectUrl?: Maybe<Scalars['String']>;
  requestCode?: Maybe<Scalars['String']>;
  testData?: Maybe<Scalars['String']>;
  testDataError?: Maybe<Scalars['String']>;
}

export interface DecisionLogicVerificationModelInput {
  bankId?: InputMaybe<Scalars['Int']>;
  decisionLogicBankListItems?: InputMaybe<Array<InputMaybe<StringStringKeyValuePairInputGraphType>>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  requestCode?: InputMaybe<Scalars['String']>;
  testData?: InputMaybe<Scalars['String']>;
  testDataError?: InputMaybe<Scalars['String']>;
}

export enum DecisionLogicWorkflowTypeEnum {
  ChangeBankAccount = 'CHANGE_BANK_ACCOUNT',
  NewLoanApplication = 'NEW_LOAN_APPLICATION',
  Refinance = 'REFINANCE'
}

export interface LayoutViewModel {
  accessType: AccessTypes;
  customerServicePhone?: Maybe<Scalars['String']>;
  enableNewIovation?: Maybe<Scalars['Boolean']>;
  greetingFirstName?: Maybe<Scalars['String']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  isLoggedIn: Scalars['Boolean'];
  isRapidCash: Scalars['Boolean'];
  loanCount: Scalars['Int'];
  persistedMessage?: Maybe<PersistedMessageViewModel>;
  requiresPasswordReset?: Maybe<Scalars['Boolean']>;
  secureSiteAddress?: Maybe<Scalars['String']>;
  sessionInfo?: Maybe<Scalars['String']>;
  showApplyNowButton: Scalars['Boolean'];
  siteAddress?: Maybe<Scalars['String']>;
}

export enum MessageType {
  Modal = 'MODAL',
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export interface Mutations {
  decisionLogicVerificationPost?: Maybe<DecisionLogicVerificationModel>;
  log?: Maybe<Scalars['Boolean']>;
  persistStoreApplyInputModel?: Maybe<StoreApplyViewModel>;
}


export interface MutationsDecisionLogicVerificationPostArgs {
  model?: InputMaybe<DecisionLogicVerificationModelInput>;
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}


export interface MutationsLogArgs {
  data?: InputMaybe<Array<InputMaybe<StringStringKeyValuePairInputGraphType>>>;
  message?: InputMaybe<Scalars['String']>;
}


export interface MutationsPersistStoreApplyInputModelArgs {
  model?: InputMaybe<StoreApplyInputModel>;
}

export interface PersistedMessageViewModel {
  text?: Maybe<Scalars['String']>;
  type: MessageType;
  viewName?: Maybe<Scalars['String']>;
}

export interface Queries {
  decisionLogicFailGet?: Maybe<DecisionLogicFailModel>;
  decisionLogicPassGet?: Maybe<DecisionLogicPassGetResponse>;
  decisionLogicVerificationTestData?: Maybe<DecisionLogicVerificationModel>;
  getContactSupportViewModel?: Maybe<ContactSupportViewModel>;
  getCountries?: Maybe<Array<Maybe<StringStringKeyValuePairGraphType>>>;
  getCustomerServiceInfo?: Maybe<CustomerServiceInfoModel>;
  getDecisionLogicVerificationModel?: Maybe<DecisionLogicVerificationModel>;
  getLayoutViewModel?: Maybe<LayoutViewModel>;
  getNonRestrictedStates?: Maybe<Array<Maybe<StringStringKeyValuePairGraphType>>>;
  getSpreedlyTokenViewModel?: Maybe<SpreedlyTokenViewModel>;
  getStoreApplyViewModel?: Maybe<StoreApplyViewModel>;
  yodleeFailGet?: Maybe<YodleeFailModel>;
  yodleePassGet?: Maybe<YodleePassGetResponse>;
}


export interface QueriesDecisionLogicFailGetArgs {
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}


export interface QueriesDecisionLogicPassGetArgs {
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}


export interface QueriesDecisionLogicVerificationTestDataArgs {
  model?: InputMaybe<DecisionLogicVerificationModelInput>;
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}


export interface QueriesGetContactSupportViewModelArgs {
  contactKey?: InputMaybe<Scalars['String']>;
  isEod?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  noRefresh?: InputMaybe<Scalars['Boolean']>;
}


export interface QueriesGetCountriesArgs {
  topCode?: InputMaybe<Scalars['String']>;
}


export interface QueriesGetDecisionLogicVerificationModelArgs {
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}


export interface QueriesGetSpreedlyTokenViewModelArgs {
  creditCardKey?: InputMaybe<Scalars['String']>;
}


export interface QueriesGetStoreApplyViewModelArgs {
  state?: InputMaybe<Scalars['String']>;
}


export interface QueriesYodleeFailGetArgs {
  workflowType?: InputMaybe<YodleeWorkflowTypeEnum>;
}


export interface QueriesYodleePassGetArgs {
  workflowType?: InputMaybe<YodleeWorkflowTypeEnum>;
}

export interface SpreedlyTokenViewModel {
  customerName?: Maybe<Scalars['String']>;
  fundingCardBin?: Maybe<Scalars['String']>;
  fundingCardMonth?: Maybe<Scalars['String']>;
  fundingCardNumber?: Maybe<Scalars['String']>;
  fundingCardYear?: Maybe<Scalars['String']>;
  spreedlyToken?: Maybe<Scalars['String']>;
  spreedlyTokenAdded: Scalars['Boolean'];
}

export interface StoreApplyInputModel {
  address?: InputMaybe<AddressModelInput>;
  confirmEmail?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  marketingConsent?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}

export interface StoreApplyViewModel {
  isEmailSent: Scalars['Boolean'];
  isNonMarketLocation: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
}

export interface StringStringKeyValuePairGraphType {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface StringStringKeyValuePairInputGraphType {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface YodleeFailModel {
  canUseElectronicPaystub: Scalars['Boolean'];
  canUsePictureUtility: Scalars['Boolean'];
  incomeNeedsVerified: Scalars['Boolean'];
  loanApplicationKey: Scalars['Int'];
  pictureUtilityDownloadUrl?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface YodleePassGetResponse {
  redirectUrl?: Maybe<Scalars['String']>;
}

export enum YodleeWorkflowTypeEnum {
  ChangeBankAccount = 'CHANGE_BANK_ACCOUNT',
  NewLoanApplication = 'NEW_LOAN_APPLICATION',
  Refinance = 'REFINANCE'
}

export type GetContactSupportViewModelQueryVariables = Exact<{
  key?: InputMaybe<Scalars['String']>;
  contactKey?: InputMaybe<Scalars['String']>;
  noRefresh?: InputMaybe<Scalars['Boolean']>;
  isEod?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetContactSupportViewModelQuery = { getContactSupportViewModel?: { contactKey?: string, errorId?: string, errorUrl?: string, errorMessage?: string, exceptionText?: string, isInternal: boolean, redirectUrl?: string, referenceNumber?: string, resourceKey?: string } };

export type DecisionLogicFailGetQueryVariables = Exact<{
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}>;


export type DecisionLogicFailGetQuery = { decisionLogicFailGet?: { canUseElectronicPaystub: boolean, canUsePictureUtility: boolean, incomeNeedsVerified: boolean, loanApplicationKey: number, pictureUtilityDownloadUrl?: string, redirectUrl?: string } };

export type DecisionLogicPassGetQueryVariables = Exact<{
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}>;


export type DecisionLogicPassGetQuery = { decisionLogicPassGet?: { redirectUrl?: string } };

export type GetDecisionLogicVerificationModelQueryVariables = Exact<{
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}>;


export type GetDecisionLogicVerificationModelQuery = { getDecisionLogicVerificationModel?: { bankId: number, redirectUrl?: string, requestCode?: string, testData?: string, testDataError?: string, decisionLogicBankListItems?: Array<{ key: string, value: string }> } };

export type DecisionLogicVerificationPostMutationVariables = Exact<{
  model?: InputMaybe<DecisionLogicVerificationModelInput>;
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}>;


export type DecisionLogicVerificationPostMutation = { decisionLogicVerificationPost?: { bankId: number, redirectUrl?: string, requestCode?: string, testData?: string, testDataError?: string, decisionLogicBankListItems?: Array<{ key: string, value: string }> } };

export type DecisionLogicVerificationTestDataQueryVariables = Exact<{
  model?: InputMaybe<DecisionLogicVerificationModelInput>;
  workflowType?: InputMaybe<DecisionLogicWorkflowTypeEnum>;
}>;


export type DecisionLogicVerificationTestDataQuery = { decisionLogicVerificationTestData?: { bankId: number, redirectUrl?: string, requestCode?: string, testData?: string, testDataError?: string, decisionLogicBankListItems?: Array<{ key: string, value: string }> } };

export type GetCustomerServiceInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerServiceInfoQuery = { getCustomerServiceInfo?: { email?: string, phone?: string } };

export type GetLayoutViewModelQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLayoutViewModelQuery = { getLayoutViewModel?: { accessType: AccessTypes, customerServicePhone?: string, enableNewIovation?: boolean, greetingFirstName?: string, isInternal?: boolean, isLoggedIn: boolean, isRapidCash: boolean, loanCount: number, secureSiteAddress?: string, sessionInfo?: string, showApplyNowButton: boolean, siteAddress?: string, requiresPasswordReset?: boolean, persistedMessage?: { text?: string, type: MessageType } } };

export type GetCountriesQueryVariables = Exact<{
  topCode: Scalars['String'];
}>;


export type GetCountriesQuery = { getCountries?: Array<{ key: string, value: string }> };

export type GetNonRestrictedStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonRestrictedStatesQuery = { getNonRestrictedStates?: Array<{ key: string, value: string }> };

export type LogMutationVariables = Exact<{
  message?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Array<InputMaybe<StringStringKeyValuePairInputGraphType>> | InputMaybe<StringStringKeyValuePairInputGraphType>>;
}>;


export type LogMutation = { log?: boolean };

export type GetSpreedlyTokenViewModelQueryVariables = Exact<{
  creditCardKey: Scalars['String'];
}>;


export type GetSpreedlyTokenViewModelQuery = { getSpreedlyTokenViewModel?: { customerName?: string, fundingCardBin?: string, fundingCardMonth?: string, fundingCardNumber?: string, fundingCardYear?: string, spreedlyToken?: string, spreedlyTokenAdded: boolean } };

export type GetStoreApplyViewModelQueryVariables = Exact<{
  state?: InputMaybe<Scalars['String']>;
}>;


export type GetStoreApplyViewModelQuery = { getStoreApplyViewModel?: { isEmailSent: boolean, state?: string, isNonMarketLocation: boolean } };

export type PersistStoreApplyInputModelMutationVariables = Exact<{
  model: StoreApplyInputModel;
}>;


export type PersistStoreApplyInputModelMutation = { persistStoreApplyInputModel?: { isEmailSent: boolean, state?: string, isNonMarketLocation: boolean } };

export type YodleeFailGetQueryVariables = Exact<{
  workflowType?: InputMaybe<YodleeWorkflowTypeEnum>;
}>;


export type YodleeFailGetQuery = { yodleeFailGet?: { canUseElectronicPaystub: boolean, canUsePictureUtility: boolean, incomeNeedsVerified: boolean, loanApplicationKey: number, pictureUtilityDownloadUrl?: string, redirectUrl?: string } };

export type YodleePassGetQueryVariables = Exact<{
  workflowType?: InputMaybe<YodleeWorkflowTypeEnum>;
}>;


export type YodleePassGetQuery = { yodleePassGet?: { redirectUrl?: string } };


export class GraphQLClient extends BaseClient {
  path = '/graphql';
  

      public getContactSupportViewModel(key: GetContactSupportViewModelQueryVariables['key'], contactKey: GetContactSupportViewModelQueryVariables['contactKey'], noRefresh: GetContactSupportViewModelQueryVariables['noRefresh'], isEod: GetContactSupportViewModelQueryVariables['isEod'], signal?: AbortSignal | RequestInit) {
          return this.request<GetContactSupportViewModelQuery>(`query GetContactSupportViewModel($key: String, $contactKey: String, $noRefresh: Boolean, $isEod: Boolean) {
    getContactSupportViewModel(key: $key, contactKey: $contactKey, noRefresh: $noRefresh, isEod: $isEod) {
        contactKey
        errorId
        errorUrl
        errorMessage
        exceptionText
        isInternal
        redirectUrl
        referenceNumber
        resourceKey
    }
}`, { key,contactKey,noRefresh,isEod }, createRequestInit(signal), 'GetContactSupportViewModel'); 
      }
  

      public decisionLogicFailGet(workflowType: DecisionLogicFailGetQueryVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<DecisionLogicFailGetQuery>(`query DecisionLogicFailGet($workflowType: DecisionLogicWorkflowTypeEnum ) {
    decisionLogicFailGet(workflowType: $workflowType) {
        canUseElectronicPaystub
        canUsePictureUtility
        incomeNeedsVerified
        loanApplicationKey
        pictureUtilityDownloadUrl
        redirectUrl
    }
}`, { workflowType }, createRequestInit(signal), 'DecisionLogicFailGet'); 
      }
  

      public decisionLogicPassGet(workflowType: DecisionLogicPassGetQueryVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<DecisionLogicPassGetQuery>(`query DecisionLogicPassGet($workflowType: DecisionLogicWorkflowTypeEnum ) {
    decisionLogicPassGet(workflowType: $workflowType) {
        redirectUrl
    }
}`, { workflowType }, createRequestInit(signal), 'DecisionLogicPassGet'); 
      }
  

      public decisionLogicVerificationPost(model: DecisionLogicVerificationPostMutationVariables['model'], workflowType: DecisionLogicVerificationPostMutationVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<DecisionLogicVerificationPostMutation>(`mutation DecisionLogicVerificationPost($model: DecisionLogicVerificationModelInput, $workflowType: DecisionLogicWorkflowTypeEnum) {
    decisionLogicVerificationPost(model: $model, workflowType: $workflowType) {
        bankId
        decisionLogicBankListItems {
            key
            value
        }
        redirectUrl
        requestCode
        testData
        testDataError
    }
}`, { model,workflowType }, createRequestInit(signal), 'DecisionLogicVerificationPost');
      }
  

      public getDecisionLogicVerificationModel(workflowType: GetDecisionLogicVerificationModelQueryVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<GetDecisionLogicVerificationModelQuery>(`query GetDecisionLogicVerificationModel($workflowType: DecisionLogicWorkflowTypeEnum) {
    getDecisionLogicVerificationModel(workflowType: $workflowType) {
        bankId
        decisionLogicBankListItems {
            key
            value
        }
        redirectUrl
        requestCode
        testData
        testDataError
    }
}`, { workflowType }, createRequestInit(signal), 'GetDecisionLogicVerificationModel'); 
      }
  

      public decisionLogicVerificationTestData(model: DecisionLogicVerificationTestDataQueryVariables['model'], workflowType: DecisionLogicVerificationTestDataQueryVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<DecisionLogicVerificationTestDataQuery>(`query DecisionLogicVerificationTestData($model: DecisionLogicVerificationModelInput, $workflowType: DecisionLogicWorkflowTypeEnum) {
    decisionLogicVerificationTestData(model: $model, workflowType: $workflowType) {
        bankId
        decisionLogicBankListItems {
            key
            value
        }
        redirectUrl
        requestCode
        testData
        testDataError
    }
}`, { model,workflowType }, createRequestInit(signal), 'DecisionLogicVerificationTestData'); 
      }
  

      public getCustomerServiceInfo(signal?: AbortSignal | RequestInit) {
          return this.request<GetCustomerServiceInfoQuery>(`query GetCustomerServiceInfo {
    getCustomerServiceInfo {
        email
        phone
    }
}`, {}, createRequestInit(signal), 'GetCustomerServiceInfo'); 
      }
  

      public getLayoutViewModel(signal?: AbortSignal | RequestInit) {
          return this.request<GetLayoutViewModelQuery>(`query GetLayoutViewModel {
    getLayoutViewModel {
        accessType
        customerServicePhone
        enableNewIovation
        greetingFirstName
        isInternal
        isLoggedIn
        isRapidCash
        loanCount
        persistedMessage {
            text
            type
        }
        secureSiteAddress
        sessionInfo
        showApplyNowButton
        siteAddress
        requiresPasswordReset
    }
}`, {}, createRequestInit(signal), 'GetLayoutViewModel'); 
      }
  

      public getCountries(topCode: GetCountriesQueryVariables['topCode'], signal?: AbortSignal | RequestInit) {
          return this.request<GetCountriesQuery>(`query GetCountries($topCode: String!) {
    getCountries(topCode: $topCode) {
        key
        value
    }
}`, { topCode }, createRequestInit(signal), 'GetCountries'); 
      }
  

      public getNonRestrictedStates(signal?: AbortSignal | RequestInit) {
          return this.request<GetNonRestrictedStatesQuery>(`query GetNonRestrictedStates {
    getNonRestrictedStates {
        key
        value
    }
}`, {}, createRequestInit(signal), 'GetNonRestrictedStates'); 
      }
  

      public log(message: LogMutationVariables['message'], data: LogMutationVariables['data'], signal?: AbortSignal | RequestInit) {
          return this.request<LogMutation>(`mutation Log($message: String, $data: [StringStringKeyValuePairInputGraphType]) {
    log(message: $message, data: $data)
}`, { message,data }, createRequestInit(signal), 'Log');
      }
  

      public getSpreedlyTokenViewModel(creditCardKey: GetSpreedlyTokenViewModelQueryVariables['creditCardKey'], signal?: AbortSignal | RequestInit) {
          return this.request<GetSpreedlyTokenViewModelQuery>(`query GetSpreedlyTokenViewModel($creditCardKey: String!) {
    getSpreedlyTokenViewModel(creditCardKey: $creditCardKey) {
        customerName
        fundingCardBin
        fundingCardMonth
        fundingCardNumber
        fundingCardYear
        spreedlyToken
        spreedlyTokenAdded
    }
}`, { creditCardKey }, createRequestInit(signal), 'GetSpreedlyTokenViewModel'); 
      }
  

      public persistStoreApplyInputModel(model: PersistStoreApplyInputModelMutationVariables['model'], signal?: AbortSignal | RequestInit) {
          return this.request<PersistStoreApplyInputModelMutation>(`mutation PersistStoreApplyInputModel($model: StoreApplyInputModel!) {
    persistStoreApplyInputModel(model: $model) {
        isEmailSent
        state
        isNonMarketLocation
    }
}`, { model }, createRequestInit(signal), 'PersistStoreApplyInputModel');
      }
  

      public getStoreApplyViewModel(state: GetStoreApplyViewModelQueryVariables['state'], signal?: AbortSignal | RequestInit) {
          return this.request<GetStoreApplyViewModelQuery>(`query GetStoreApplyViewModel($state: String) {
    getStoreApplyViewModel(state: $state) {
        isEmailSent
        state
        isNonMarketLocation
    }
}`, { state }, createRequestInit(signal), 'GetStoreApplyViewModel'); 
      }
  

      public yodleeFailGet(workflowType: YodleeFailGetQueryVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<YodleeFailGetQuery>(`query YodleeFailGet($workflowType: YodleeWorkflowTypeEnum ) {
    yodleeFailGet(workflowType: $workflowType) {
        canUseElectronicPaystub
        canUsePictureUtility
        incomeNeedsVerified
        loanApplicationKey
        pictureUtilityDownloadUrl
        redirectUrl
    }
}`, { workflowType }, createRequestInit(signal), 'YodleeFailGet'); 
      }
  

      public yodleePassGet(workflowType: YodleePassGetQueryVariables['workflowType'], signal?: AbortSignal | RequestInit) {
          return this.request<YodleePassGetQuery>(`query YodleePassGet($workflowType: YodleeWorkflowTypeEnum ) {
    yodleePassGet(workflowType: $workflowType) {
        redirectUrl
    }
}`, { workflowType }, createRequestInit(signal), 'YodleePassGet'); 
      }
  
}