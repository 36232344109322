
export function LoadScript(url: string, attributes: Array<{ name: string, value: string }> = []) {
    const head = document.querySelector("head");

    if (head === null) return;

    const script = document.createElement("script");
    script.setAttribute("src", url);

    attributes.forEach((attr) => {
        script.setAttribute(attr.name, attr.value);
    });

    head.appendChild(script);

    return function () {
        head.removeChild(script);
    };
};

export function LoadScriptLegacy(url: string) {
    return new Promise<void>(function (resolve) {
        const script = document.createElement('script');
        script.onload = () => resolve();
        script.src = url;
        const head = document.querySelector('head');
        if (head) {
            head.appendChild(script);
        }
    });
}