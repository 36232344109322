import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLayoutContext } from '../layout/Layout';
import { Routes } from '../Routes';
import { equalsIgnoringCase } from './StringHelper';

export function useNavigation() {
    const navigate = useNavigate();
    const { update } = useLayoutContext();

    const nav = useMemo(
        () => ({
            redirect(href: string) {
                update();

                const loc = window.location;
                const url = new URL(href, window.location.toString());
                // Logout route needs to hit the controller endpoint instead of being redirected
                if (url.host === loc.host && !equalsIgnoringCase(href, Routes.User.Logout)) {
                    window.scrollTo(0, 0);
                    navigate(url.pathname + url.search);
                } else {
                    window.location.href = href;
                }
            },
        }),
        [navigate, update]
    );
    return nav;
}
