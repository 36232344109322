import React, { useEffect, Fragment } from 'react';

export function WebAnalytics() {
    const u = window.location.toString();
    const r = window.document.referrer;
    const src = `/Utilities/wa.js?u=${encodeURIComponent(u)}&r=${encodeURIComponent(r)}`;

    useEffect(
        function() {
            window.navigator.sendBeacon(src);
        },
        [src]
    );

    return <Fragment />;
}
