import { Icon, IconSize } from './Icon';

interface SpinnerProps {
    className?: string;
    inline?: boolean;
    size?: IconSize;
}

export const Spinner = (props: SpinnerProps) =>
    props.inline ? (
        <Icon name="circle-notch" className={props.className ?? 'mr-1'} spin />
    ) : (
        <div className={props.className || 'h2'} style={{ textAlign: 'center' }}>
            <Icon name="circle-notch" spin size={props.size} />
        </div>
    );
