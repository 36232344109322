import { Buffer } from 'buffer/';

const algorithm = 'RSASSA-PKCS1-v1_5';
const hashAlgorithm = 'SHA-256';
const privateKeyFormat = 'pkcs8';
const publicKeyFormat = 'spki';
const KEY_ALIAS = 'Tiger';
export const privateKeyPath = `${KEY_ALIAS}:semi-private-key`;
export const publicKeyPath = `${KEY_ALIAS}:public-key`;
export const fingerprintPath = `${KEY_ALIAS}:public-key-hash`;

export interface Certificate {
    fingerprint: string;
    publicKey: string;
}

export function createNewCertificate() {
    clearCertificates();
    return getOrCreatePublicCertificate();
}

export function getCertificateFingerprint(): string | undefined {
    return getPublicCertificate()?.fingerprint;
}

export async function sign(payload: string): Promise<string> {
    const { privateKey } = await getOrCreateKeys();
    if (!privateKey) throw new Error('Unable to generate a private key');
    const result = await crypto.subtle.sign(algorithm, privateKey, Buffer.from(payload));
    return convertToBase64(result);
}

export function getPublicCertificate(): Certificate | undefined {
    const publicKey = sessionStorage.getItem(publicKeyPath);
    const fingerprint = sessionStorage.getItem(fingerprintPath);
    if (!publicKey || !fingerprint) {
        return undefined;
    }
    return { publicKey, fingerprint };
}

export async function getOrCreatePublicCertificate(): Promise<Certificate> {
    let cert = getPublicCertificate();
    if (!cert) {
        ({ cert } = await createKeys());
    }
    return cert;
}

export function clearCertificates(): void {
    sessionStorage.removeItem(publicKeyPath);
    sessionStorage.removeItem(privateKeyPath);
    sessionStorage.removeItem(fingerprintPath);
}

async function getOrCreateKeys() {
    let keys = await getKeys();
    if (!keys) {
        ({ keys } = await createKeys());
    }
    return keys ?? { publicKey: undefined, privateKey: undefined };
}

async function createKeys() {
    if (!crypto.subtle) return { cert: { fingerprint: '', publicKey: '' } };
    const keys = await crypto.subtle.generateKey(
        {
            name: algorithm,
            modulusLength: 2048,
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: hashAlgorithm,
        },
        true,
        ['sign', 'verify']
    );
    const cert = await storeKeys(keys);
    return { keys, cert };
}

async function getKeys() {
    const priv = convertFromBase64(sessionStorage.getItem(privateKeyPath));
    const pub = convertFromBase64(sessionStorage.getItem(publicKeyPath));
    if (!pub || !priv || !sessionStorage.getItem(fingerprintPath)) return undefined;

    const privateKey = await crypto.subtle.importKey(
        privateKeyFormat,
        priv,
        {
            name: algorithm,
            hash: hashAlgorithm,
        },
        true,
        ['sign']
    );

    const publicKey = await crypto.subtle.importKey(
        publicKeyFormat,
        pub,
        {
            name: algorithm,
            hash: hashAlgorithm,
        },
        true,
        ['verify']
    );

    return { privateKey, publicKey };
}

async function storeKeys(keys: CryptoKeyPair): Promise<Certificate> {
    const priv = await crypto.subtle.exportKey(privateKeyFormat, keys.privateKey);
    const pub = await crypto.subtle.exportKey(publicKeyFormat, keys.publicKey);
    sessionStorage.setItem(privateKeyPath, convertToBase64(priv));
    const pubb64 = convertToBase64(pub);
    const fingerprint = await hash(pubb64);
    sessionStorage.setItem(publicKeyPath, pubb64);
    sessionStorage.setItem(fingerprintPath, fingerprint);
    return { publicKey: pubb64, fingerprint };
}

export function convertToBase64(value: ArrayBuffer | string) {
    if (typeof value === 'string') return Buffer.from(value).toString('base64');
    return Buffer.from(value).toString('base64');
}

export function convertFromBase64(value: string | null | undefined) {
    if (!value) return undefined;
    return Buffer.from(value, 'base64');
}

async function hash(str: string) {
    const buffer = Buffer.from(str);
    const result = await crypto.subtle.digest(hashAlgorithm, buffer);
    return convertToBase64(result);
}
