import { Fragment } from 'react';
import { LoadScript } from '../shared/ScriptHelper';
import { useEffectAsync } from '../shared/useEffectAsync';

declare global {
    interface Window {
        BOOMR?: { init: Function };
    }
}

export function Boomerang() {
    useEffectAsync(async () => {
        await LoadScript('/Scripts/f5-bmrg.js');
        const { BOOMR } = window;
        BOOMR?.init({
            beacon_url: '/boomerang-beacon.html',
            RT: {
                cookie: 'EH-RUM-RT',
            },
        });
    }, []);
    return <Fragment />;
}
