import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faCheckSquare,
    faEnvelope as faEnvelopeO,
    faFileAlt,
    faFileImage,
    faFileWord,
    faFilePdf,
    faFileArchive,
    faFile,
    faEye,
    faSave,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleLeft,
    faAngleDoubleRight,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faCamera,
    faCaretDown,
    faCaretRight,
    faCheck,
    faCircle,
    faCircleNotch,
    faDollarSign,
    faEnvelope,
    faExpandAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faList,
    faLock,
    faLongArrowAltLeft,
    faQuestion,
    faMapMarker,
    faMoneyCheck,
    faPlus,
    faPrint,
    faQuestionCircle,
    faSearch,
    faUser,
    faUniversity,
    faWheelchair,
    faAngleDoubleDown,
    faMoneyCheckAlt
} from '@fortawesome/free-solid-svg-icons';

import { ReactComponent as Close } from '../layout/assets/close.svg';
import { ReactComponent as Lock } from '../layout/assets/lock.svg';
import { ReactComponent as Hamburger } from '../layout/assets/hamburger.svg';
import { ReactComponent as Phone } from '../layout/assets/phone.svg';

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | '2x' | '3x' | '4x' | '5x' | '7x' | '10x';

const svgs = {
    times: Close,
    bars: Hamburger,
    lock: Lock,
    phone: Phone,
};

const all = {
    'angle-left': faAngleLeft,
    'angle-right': faAngleRight,
    'angle-double-right': faAngleDoubleRight,
    'angle-down': faAngleDown,
    'angle-double-down': faAngleDoubleDown,
    'angle-up': faAngleUp,
    bank: faUniversity,
    'back-arrow': faLongArrowAltLeft,
    calendar: faCalendar,
    camera: faCamera,
    'caret-down': faCaretDown,
    'caret-right': faCaretRight,
    check: faCheck,
    'check-square': faCheckSquare,
    'money-check': faMoneyCheck,
    circle: faCircle,
    'circle-notch': faCircleNotch,
    'dollar-sign': faDollarSign,
    envelope: faEnvelope,
    'envelope-o': faEnvelopeO,
    'exclamation-triangle': faExclamationTriangle,
    'exclamation-circle': faExclamationCircle,
    expand: faExpandAlt,
    eye: faEye,
    file: faFile,
    'file-image': faFileImage,
    'file-pdf': faFilePdf,
    'file-text': faFileAlt,
    'file-word': faFileWord,
    'file-zip': faFileArchive,
    list: faList,
    lock: faLock,
    'money-check-alt': faMoneyCheckAlt,
    pin: faMapMarker,
    plus: faPlus,
    print: faPrint,
    question: faQuestion,
    'question-circle': faQuestionCircle,
    save: faSave,
    search: faSearch,
    user: faUser,
    wheelchair: faWheelchair,
};

export type Icons = keyof typeof all | keyof typeof svgs;

interface IconProps {
    name: Icons;
    className?: string;
    flip?: FontAwesomeIconProps['flip'];
    rotate?: FontAwesomeIconProps['rotate'];
    size?: IconSize;
    spin?: boolean;
    title?: string;
}

export function Icon(props: IconProps) {
    const className = (props.className ?? '') + ' ' + (props.spin ? ' fa-spin' : '') + ' ' + (props.size ? ` fa-${props.size}` : '');
    const svgName = props.name as keyof typeof svgs;
    if (svgName in svgs) {
        const Svg = svgs[svgName];
        return <Svg title={props.title} aria-hidden="true" className={'svg-inline--fa fa-w-18 ' + className} />;
    }

    const iconName = props.name as keyof typeof all;

    return (
        <FontAwesomeIcon
            title={props.title}
            aria-hidden="true"
            icon={all[iconName]}
            flip={props.flip}
            rotate={props.rotate}
            className={className}
        />
    );
}
