import { Fragment, useEffect } from 'react';
import { client } from '../shared/Client';
import { isProduction } from '../shared/isProduction';
import { LoadScript } from '../shared/ScriptHelper';
import { useLayoutContext } from './Layout';

function ioBbCallback(bb: string, complete: boolean) {
    if (bb && complete) {
        client.post('/Fingerprint', `vendor=Iovation&key=${encodeURIComponent(bb)}`);
    }
}

function OldIovation() {
    useEffect(function () {
        Object.assign(window, {
            io_operation: 'ioBegin',
            io_install_flash: false,
            io_install_stm: false,
            io_exclude_stm: 12,
            io_install_ip: true,
            io_bb_callback: ioBbCallback,
        });

        const url = isProduction ? 'https://mpsnare.iesnare.com/snare.js' : 'https://ci-mpsnare.iovation.com/snare.js';
        LoadScript(url);
    }, []);
    return <Fragment />;
}

function NewIovation() {
    useEffect(function () {
        const io_global_object_name = 'IGLOO';
        Object.assign(window, {
            io_global_object_name,
            [io_global_object_name]: {
                enable_flash: false,
                bb_callback: ioBbCallback,
                loader: {
                    subkey: '',
                    version: 'general5',
                },
            },
        });

        LoadScript('/static-content/loader_static5_2_2.js');
    }, []);
    return <Fragment />;
}

export function Iovation() {
    const {
        model: { enableNewIovation },
    } = useLayoutContext();
    return enableNewIovation === true ? <NewIovation /> : enableNewIovation === false ? <OldIovation /> : <Fragment />;
}
