import { useCallback, useEffect, useState } from 'react';
import { TestingClient, State } from '../GraphQL.Testing';
import {
    setCheckbox,
    setRadioButton,
    setSelectValue,
    setTextBox,
    isHtmlFormElement,
    isHtmlInputElement,
    isHtmlSelectElement,
} from './DOMHelpers';
import { useLocalization } from './localization';
import { Spinner } from './Spinner';
import { useGraphQL } from './useGraphQL';
type MockValue = string | number | (() => Promise<string | number | { model: string | number }>);
const clicked = new Set<string>();
let form: HTMLFormElement | null = null;

async function mock(query: string, value: MockValue) {
    const elements = Array.from(document.querySelectorAll(query));

    let handled = false;
    for (const element of elements) {
        if (element.id && element.classList.contains('js-click')) {
            let uuid = window.location.pathname + '#' + element.id;
            if (!clicked.has(uuid)) {
                clicked.add(uuid);
                element.dispatchEvent(
                    new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: true,
                    })
                );
            }
            handled = true;
        } else if (isHtmlFormElement(element)) {
            if (typeof value === 'function') {
                const result = await value();
                value = typeof result === 'number' || typeof result === 'string' ? result : result.model;
            }

            if (isHtmlSelectElement(element)) {
                form = element.form;
                setSelectValue(element, value);
                handled = true;
            } else if (isHtmlInputElement(element)) {
                form = element.form;
                switch (element.type.toLowerCase()) {
                    case 'text':
                    case 'tel':
                    case 'password':
                        setTextBox(element, value);
                        handled = true;
                        break;
                    case 'radio':
                        setRadioButton(element);
                        break;
                    case 'checkbox':
                        setCheckbox(element);
                        break;
                    default:
                        throw new Error(`Unhandled input type ${element.type}`);
                }
            }
        }
    }

    return handled;
}

export function MockData() {
    const [busy, setBusy] = useState(false);

    const locale = useLocalization();
    const graphQLClient = useGraphQL(TestingClient);

    const generateMockData = useCallback(async () => {
        setBusy(true);

        const getRandomNumber = (minValue: number, maxValue: number) => {
            return graphQLClient.getRandomNumber(minValue, maxValue).then((x) => x?.data?.getRandomNumber?.randomNumber ?? 0);
        };

        const currentState = (document.querySelector('.js-state') as HTMLSelectElement)?.selectedOptions?.[0].text?.replace(/\W+/g, '');
        const currentStatePromise =
            currentState === undefined || currentState === ''
                ? graphQLClient.getAddress(State.Unknown)
                : graphQLClient.getAddress(State[currentState as keyof typeof State]);
        const states = locale.nonRestrictedStateListItems;

        let queue: [string, MockValue][] = [
            ['.js-click', ''],
            ['.js-mock-0', 0],
            ['.js-mock-1', 1],
            ['.js-mock-2', 2],
            ['.js-mock-3', 3],
            ['.js-mock-4', 4],
            ['.js-mock-5', 5],
            ['.js-mock-1000', 1000],
            ['.js-random-month', () => getRandomNumber(1, 12)],
            ['.js-random-year', () => getRandomNumber(1, 30)],
            ['.js-random-number-small', () => getRandomNumber(10, 1000)],
            ['.js-random-number', () => getRandomNumber(100000, 999999)],
            ['.js-gross-income', 2200],
            ['.js-net-income', 1800],
            ['.js-ytd-income', 8500],
            ['.js-federal-tax', 200],
            ['.js-state-tax', 50],
            ['.js-fica', 84],
            ['.js-medicare', 29],
            ['.js-income-type-code', 2],
            ['.js-pay-cycle', 2],
            ['.js-pay-type', 'salary'],
            ['.js-weekly-payday', 6],
            ['.js-direct-deposit', 'True'],
            ['.js-income-frequency', '2'],
            ['.js-marital-status', 'Married'],
            ['.js-bank-name', 'Bank of America'],
            [
                '.js-bank-transit-number',
                () => graphQLClient.getBankTransitNumber().then((x) => x?.data?.getBankTransitNumber?.bankTransitNumber ?? ''),
            ],
            [
                '.js-bank-institution-number',
                () => graphQLClient.getBankInstitutionNumber().then((x) => x?.data?.getBankInstitutionNumber?.bankInstitutionNumber ?? ''),
            ],
            ['.js-bank-account-number', () => getRandomNumber(100000, 999999)],
            ['.js-first-name', () => graphQLClient.getFirstName().then((x) => x?.data?.getFirstName?.firstName ?? '')],
            ['.js-last-name', () => graphQLClient.getLastName().then((x) => x?.data?.getLastName?.lastName ?? '')],
            ['input.js-full-name', document.querySelector('.js-full-name:not(input)')?.textContent?.trim() ?? 'john doe'],
            ['.js-password', () => graphQLClient.getPassword().then((x) => x?.data?.getPassword?.password ?? '')],
            ['.js-email', () => graphQLClient.getEmail().then((x) => x?.data?.getEmail?.email ?? '')],
            [
                '.js-email-verification-code',
                () => graphQLClient.getEmailVerificationCode().then((x) => x?.data?.getEmailVerificationCode?.emailVerificationCode ?? ''),
            ],
            ['.js-visitor-email', () => graphQLClient.getVisitorEmail().then((x) => x?.data?.getVisitorEmail?.visitorEmail ?? '')],
            ['.js-security-question', 2],
            ['.js-security-question-answer', 'Rudy'],
            ['.js-checked input, input.js-checked, .required input[type=checkbox]', 1],
            ['.js-month', 5],
            ['.js-day', 15],
            ['.js-birth-year', 51], // 1952
            ['.js-phone', '2092144510'],
            ['.js-phone-masked', '(209) 214-4510'],
            [
                '.js-social-security-number',
                () => graphQLClient.getSocialSecurityNumber().then((x) => x?.data?.getSocialSecurityNumber?.socialSecurityNumber ?? ''),
            ],
            ['.js-id-type', 2],
            ['.js-id-number', () => getRandomNumber(100000, 999999)],
            [
                '.js-vin',
                () =>
                    graphQLClient
                        .getVehicleIdentificationNumber()
                        .then((x) => x?.data?.getVehicleIdentificationNumber?.vehicleIdentificationNumber ?? ''),
            ],
            ['.js-vehicle-make', 'Jeep'],
            ['.js-vehicle-model', 'Wrangler'],
            ['.js-vehicle-type', 'Passenger'],
            ['.js-vehicle-plate', 'IMA PLATE'],
            ['.js-vehicle-condition', 'EXCELLENT'],
            ['.js-fuel-type', 'Gas'],
            [
                '.js-business-ein',
                () =>
                    graphQLClient
                        .getEmployerIdentificationNumber()
                        .then((x) => x?.data?.getEmployerIdentificationNumber?.employerIdentificationNumber ?? ''),
            ],
            ['.js-business-legal', () => getRandomNumber(1, 8)],
            ['.js-business-name', 'Testing Inc.'],
            ['.js-business-purpose', 'Inventory'],
            ['.js-business-type', () => getRandomNumber(1, 11)],
            [
                '.js-customer-initials',
                () => graphQLClient.getCustomerInitials().then((x) => x?.data?.getCustomerInitials?.customerInitials ?? ''),
            ],
            ['.js-street-address', () => currentStatePromise.then((x) => x?.data?.getAddress?.address?.address ?? '')],
            ['.js-city', () => currentStatePromise.then((x) => x?.data?.getAddress?.address?.city ?? '')],
            ['.js-zip', () => currentStatePromise.then((x) => x?.data?.getAddress?.address?.zip ?? '')],
            [
                '.js-state',
                () => currentStatePromise.then((x) => states.findIndex((item) => item.value === x?.data?.getAddress?.address?.state ?? '')),
            ],
        ];

        let elementsFound = true;
        while (elementsFound && queue.length > 0) {
            const result = await Promise.all(queue.map(([query, value]) => mock(query, value)));
            elementsFound = result.some((x) => x);
            queue = queue.filter((_, i) => !result[i]);
            await Promise.delay(50); // wait for react renders
        }

        setBusy(false);
        await Promise.delay(50); // wait for react renders

        const submit = form?.querySelector('[type="submit"]') as HTMLButtonElement;
        submit?.focus?.();
    }, [graphQLClient, locale.nonRestrictedStateListItems]);

    const handleCtrlM = useCallback(
        (event: KeyboardEvent) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'm') {
                generateMockData();
            }
        },
        [generateMockData]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleCtrlM);
        return () => document.removeEventListener('keydown', handleCtrlM);
    }, [handleCtrlM]);

    return (
        <button type="button" className="btn btn-secondary" disabled={busy} onClick={generateMockData} title="CTRL+M">
            {busy && <Spinner inline />}
            Mock Form
        </button>
    );
}
