export type SessionData = ReturnType<typeof extractSessionData>;

export function extractSessionData(sessionInfo: string | undefined) {
    const entries = sessionInfo?.split('|') ?? [];
    const sessionData = {
        visitorKey: parseInt(entries[0]) || undefined,
        customerKey: parseInt(entries[1]) || undefined,
        currentLocation: parseInt(entries[2]) || undefined,
        state: entries[3],
        loanKey: parseInt(entries[4]) || undefined,
        loanAmount: parseFloat(entries[5]) || undefined,
        hasDefaultedLoan: entries[6] === '1',
        isNewCust: entries[7] === '1',
        isReturningCustomer: entries[8] === '1',
        loanApplicationKey: parseInt(entries[9]) || undefined,
        webVisitKey: parseInt(entries[10]) || undefined,
        loanStatus: entries[11],
        productType: entries[12],
        pendingReasons: entries[13]
    };
    return sessionData;
}
