/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState, Fragment, useCallback, MouseEvent, Suspense, lazy } from 'react';
import useSize from '@react-hook/size';
import { client } from './Client';

import { MockData } from './MockData';
import { confirm } from './Dialog';
import { isProduction } from './isProduction';
import { Icon } from './Icon';
import { TestToolsContainer } from './TestTools';
import { SessionData } from '../layout/extractSessionData';
import { useEffectAsync } from './useEffectAsync';
import { matchPath, useLocation } from 'react-router';
import { routeLoading, Routes } from '../Routes';
import { useEvent } from './Events';
const LazyIntegrationTests = lazy(() => import('../testing/IntegrationTests'));

interface TestingInfo {
    server: string;
    environment: string;
    db?: {
        server: string;
        name: string;
        version: string;
        scoringDatabaseVersion: string;
    };
    packageKey: number;
    ipAddress: string;
    isProduction: boolean;
    loanKey: number;
    visitorKey: number;
    customerKey: number;
    webVisitKey: number;
    lenderLocation: number;
    stateCode: string;
    assemblies: { [name: string]: string };
    envColor: string;
}

async function loadEnvironmentData(): Promise<TestingInfo> {
    const result = await client.getJson<TestingInfo>('/testing/info.json');
    return result;
}

export function TestHarness({ sessionInfo }: { sessionInfo: SessionData }) {
    const [info, setInfo] = useState<TestingInfo | undefined>(undefined);
    const [active, setActive] = useState(false);
    const [harnessRef, setHarnessRef] = useState<HTMLDivElement | null>(null);
    const [, harnessHeight] = useSize(harnessRef);
    useEffect(() => {
        if (isProduction) return;
        document.body.style.marginBottom = `${harnessHeight + 10}px`;
    }, [harnessHeight]);

    const handleDrawerHandleClick = useCallback((e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setActive((x) => !x);
    }, []);

    useEffectAsync(async () => {
        if (isProduction) return;
        const result = await loadEnvironmentData();
        setInfo(result);
    }, [setInfo]);

    const [showTests, setShowTests] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => setShowTests((prev) => prev || active || !!matchPath(Routes.Testing.IntegrationTests, pathname)), [active, pathname]);

    const [testStatus, setTestStatus] = useState<string>();
    useEvent(
        'test-status',
        useCallback((status: string) => setTestStatus(status), [])
    );

    const handleShowAssemblies = useCallback(
        () =>
            confirm(
                <>
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Assembly</th>
                                <th>Version</th>
                            </tr>
                        </thead>
                        <tbody>
                            {info &&
                                Object.entries(info.assemblies).map(([name, version]) => (
                                    <tr key={name}>
                                        <td>{name}</td>
                                        <td>{version}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </>,
                { title: 'Assembly Versions' }
            ),
        [info]
    );

    const handleShowEnvironmentData = useCallback(
        () =>
            confirm(
                <>
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Assembly</th>
                                <th>Version</th>
                            </tr>
                        </thead>
                        <tbody>
                            {info &&
                                Object.entries(info)
                                    .concat(info.db ? Object.entries(info.db) : [])
                                    .filter(([name]) => !name.match(/assemblies|db|envColor/))
                                    .map(([name, version]) => (
                                        <tr key={name}>
                                            <td>{name}</td>
                                            <td>{String(version ?? '')}</td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                </>,
                { title: 'Assembly Versions' }
            ),
        [info]
    );

    if (isProduction || !info || info.isProduction) {
        return <Fragment />;
    }

    const { webVisitKey, visitorKey } = sessionInfo;

    const getErrorUrl = () => {
        const environment = info.environment.includes('DEV') ? 'dev' : 'qa'
        const range = '1d'
        const ip = info.ipAddress;
        return `https://src-logging.${environment}.ccfi.cloud/#/events?range=${range}&filter=Client%3D'Capo'and%20IPAddress%3D'${ip}'&signal=signal-m33301`;
    }

    return (
        <div
            ref={setHarnessRef}
            className="fixed bottom-0 left-0 right-0 bg-gray-100"
            style={{
                borderTopWidth: 3,
                borderTopStyle: 'solid',
                borderTopColor: info.envColor,
            }}
        >
            <div className="flex flex-row">
                <button type="button" className="mr-3 btn btn-sm" onClick={handleShowAssemblies}>
                    {info.assemblies.Capo}
                </button>
                <button
                    type="button"
                    className="mr-3 btn btn-sm"
                    title={`${info.db?.server}.${info.db?.name}`}
                    onClick={handleShowEnvironmentData}
                >
                    {info.environment}
                </button>
                <button type="button" className="mr-3 btn btn-sm">
                    Visitor: {visitorKey || 'none'}
                </button>
                {!!testStatus && <span className="mr-3 text-red-500 btn btn-sm">{testStatus}</span>}
                <div className="flex justify-end pr-3 tt-drawer-handle flex-grow-1" onClick={handleDrawerHandleClick}>
                    <Icon name="caret-down" size="lg" flip={active ? undefined : 'vertical'} />
                </div>
            </div>
            <div className={`${active ? 'slide' : 'hidden'} flex flex-col`}>
                <div className="flex flex-row py-2 vertically-center">
                    <button type="button" className="mr-3 btn btn-sm btn-default">
                        Web Visit: {webVisitKey || 'none'}
                    </button>
                    <a
                        target="_blank"
                        className="mr-3 btn btn-link btn-sm"
                        href={getErrorUrl()}
                    >
                        Errors
                    </a>
                    <div className="flex flex-row ml-auto">
                        <div className="mr-2">
                            {showTests && (
                                <Suspense fallback={routeLoading}>
                                    <LazyIntegrationTests />
                                </Suspense>
                            )}
                        </div>
                        <MockData />
                    </div>
                </div>
                <div className="flex flex-row">
                    <TestToolsContainer />
                </div>
            </div>
        </div>
    );
}
/* eslint-enable react/jsx-no-target-blank */
