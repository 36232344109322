import { useEffect } from 'react';

export function useEffectAsync<T>(effect: ((signal: AbortSignal) => Promise<T>) | (() => Promise<T>), dependencies: unknown[]) {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const controller = new AbortController();
        effect(controller.signal);
        return function () {
            controller.abort();
        };

    }, dependencies);
    /* eslint-enable react-hooks/exhaustive-deps */
}
