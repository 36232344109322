export enum Brands {
    None,
    SpeedyCash,
    CashMoney,
    LendDirect,
    AdAstra,
}

export function parseBrand(value: string | undefined) {
    const brandName = (value ?? '').replace(/^\//g, '').split('-')[0].toLowerCase();
    switch (brandName) {
        case 'speedycash':
            return Brands.SpeedyCash;
        case 'cashmoney':
            return Brands.CashMoney;
        case 'lenddirect':
            return Brands.LendDirect;
        case 'adastra':
            return Brands.AdAstra;
        default:
            return undefined;
    }
}
