import { scheduleKeepAlive } from '../layout/useKeepAlive';
import { convertToBase64, sign, getCertificateFingerprint } from './authentication/Crypt';
import { parseDate } from './DateHelper';
import { triggerFetchEvent } from './Events';
import { parseJson } from './JsonHelper';
import { getRandomString } from './StringHelper';

let timeDifference = 0;
const startsWithSlash = RegExp.prototype.test.bind(/^\//);
const isContactSupport = RegExp.prototype.test.bind(/ContactSupport/i);
const CACHE: { [key: string]: object } = {};
const isObject = (obj: {}) => /\sObject\]$/.test(Object.prototype.toString.apply(obj));

const formEncode = (obj: string | { [key: string]: {} }, prefix?: string): string => {
    if (typeof obj === 'string') {
        return obj;
    }

    return Object.keys(obj)
        .map((k) =>
            isObject(obj[k]) ? formEncode(obj[k], k + '.') : `${encodeURIComponent(k)}=${encodeURIComponent(String(obj[k] || ''))}`
        )
        .filter(Boolean)
        .join('&');
};

export function resetCache() {
    for (const key of Object.keys(CACHE)) {
        delete CACHE[key];
    }
}

export class Client {
    /**
     * Executes a form post to the given `path` and expects a JSON result of type `T`
     */
    postToJson = async <T>(path: string, body?: string | {}, options?: RequestInit) =>
        parseJson<T>(await this.post(path, body, options).then((x) => x.text()));

    /**
     * Executes a form post to the given `path`
     */
    post = (path: string, body?: string | {}, options?: RequestInit) =>
        this.request(path, {
            method: 'post',
            body: body && formEncode(body),
            ...options,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...(options && options.headers),
            },
        });

    /**
     * Executes a JSON post to the given `path` and expects a JSON result of type `T`
     */
    postJsonToJson = <T>(path: string, body: object, options?: RequestInit) =>
        this.postToJson<T>(path, JSON.stringify(body), {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                ...(options && options.headers),
            },
        });

    getJson = async <T>(path: string, options?: RequestInit) => {
        const response = await this.request(path, options);
        const result = parseJson<T>(await response.text());
        if (this.isRedirect(result)) {
            window.location.href = result.redirect;
        }
        return result;
    };

    getCachedJson = <T>(path: string, options?: RequestInit) => {
        let promise = CACHE[path] as Promise<T>;
        if (!promise) {
            promise = CACHE[path] = this.getJson<T>(path, options);
        }
        return promise;
    };

    normalizePath = (path: string) => (startsWithSlash(path) ? path : `/api/${path}`);

    request = async (path: string, options?: RequestInit) => {
        const normalizedPath = this.normalizePath(path);

        const body = options?.body ?? '';
        const fingerprint = getCertificateFingerprint();

        const init: RequestInit = {
            ...options,
            credentials: 'same-origin',
            headers: {
                'X-Requested-With': 'fetch',
                ...options?.headers,
            },
        };

        if (fingerprint && typeof body === 'string') {
            const timestamp = new Date(Date.now() + timeDifference).toISOString();
            const nonce = getRandomString(10);
            const payload = body + timestamp + nonce;
            const signature = await sign(payload);

            const authorization = convertToBase64(['sha256', signature, fingerprint, timestamp, nonce].join('\n'));
            init.headers = { ...init.headers, Authorization: `x-cert ${authorization}` };
        }

        const response = await fetch(normalizedPath, init);
        const serverDateResponse = response.headers?.get('Date');
        const serverDate = serverDateResponse ? parseDate(serverDateResponse) : null;
        if (serverDate) {
            timeDifference = serverDate.getTime() - Date.now();
        }

        scheduleKeepAlive();
        triggerFetchEvent(normalizedPath, init, response);

        if (!response || (response.redirected && isContactSupport(response.url)) || response.status >= 500) {
            const error: Error & { log?: boolean } = new Error('Server error: ' + JSON.stringify(response));
            error.log = false;
            throw error;
        }

        return response;
    };

    isRedirect(obj: {}): obj is { redirect: string } {
        return !!(obj as { redirect: string }).redirect;
    }
}

export const client = new Client();
