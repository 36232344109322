// This code was generated by a tool. Do not edit manually.
import { BaseClient, createRequestInit } from './GraphQL.BaseClient';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Char: string;
  DateTime: Date;
  DateTimeOffset: Date;
}

export interface ActivityData {
  entries?: Maybe<Array<Maybe<ActivityInfo>>>;
  url?: Maybe<Scalars['String']>;
}

export interface ActivityInfo {
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Float'];
  size?: Maybe<Scalars['Int']>;
  spanId?: Maybe<Scalars['String']>;
  startedAt: Scalars['DateTimeOffset'];
}

export interface AddressModel {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export enum LoanType {
  None = 'NONE',
  Payday = 'PAYDAY',
  Signature = 'SIGNATURE',
  Title = 'TITLE'
}

export interface LogData {
  entries?: Maybe<Array<Maybe<LogEntity>>>;
  url?: Maybe<Scalars['String']>;
}

export interface LogEntity {
  categoryName?: Maybe<Scalars['String']>;
  enteredAt: Scalars['DateTimeOffset'];
  exception?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  logLevel: LogLevel;
  message?: Maybe<Scalars['String']>;
  scopes?: Maybe<Array<Maybe<LogScopeEntity>>>;
  serviceId?: Maybe<Scalars['String']>;
  spanId?: Maybe<Scalars['String']>;
  traceId?: Maybe<Scalars['String']>;
}

export enum LogLevel {
  Critical = 'CRITICAL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Information = 'INFORMATION',
  None = 'NONE',
  Trace = 'TRACE',
  Warning = 'WARNING'
}

export interface LogScopeEntity {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface MockDataInputModel {
  address?: Maybe<AddressModel>;
  bankInstitutionNumber?: Maybe<Scalars['String']>;
  bankTransitNumber?: Maybe<Scalars['String']>;
  birthDate: Scalars['DateTime'];
  customerInitials?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerificationCode?: Maybe<Scalars['String']>;
  employerIdentificationNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  randomNumber?: Maybe<Scalars['Int']>;
  randomString?: Maybe<Scalars['String']>;
  socialSecurityNumber?: Maybe<Scalars['String']>;
  vehicleIdentificationNumber?: Maybe<Scalars['String']>;
  visitorEmail?: Maybe<Scalars['String']>;
}

export enum ProductType {
  /** Cashed Check */
  CashedCheck = 'CASHED_CHECK',
  /** Currency Exchange */
  CurrencyExchange = 'CURRENCY_EXCHANGE',
  /** Gold */
  Gold = 'GOLD',
  /** Installment */
  Installment = 'INSTALLMENT',
  None = 'NONE',
  /** Open End */
  OpenEnd = 'OPEN_END',
  /** OptPlus */
  OptPlus = 'OPT_PLUS',
  /** Single Pay */
  SinglePay = 'SINGLE_PAY'
}

export interface SearchCriteria {
  canDoNewApp?: InputMaybe<Scalars['Boolean']>;
  canExtend?: InputMaybe<Scalars['Boolean']>;
  canMakePayment?: InputMaybe<Scalars['Boolean']>;
  canRefinance?: InputMaybe<Scalars['Boolean']>;
  hasLoan?: InputMaybe<Scalars['Boolean']>;
  isDefaulted?: InputMaybe<Scalars['Boolean']>;
  loanAuditCategory?: InputMaybe<Scalars['String']>;
  loanType?: InputMaybe<LoanType>;
  numOpenLoans?: InputMaybe<Scalars['Int']>;
  productType?: InputMaybe<ProductType>;
  state?: InputMaybe<Scalars['String']>;
  visitorKey?: InputMaybe<Scalars['Int']>;
}

export enum State {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Alberta = 'ALBERTA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  /** British Columbia */
  BritishColumbia = 'BRITISH_COLUMBIA',
  California = 'CALIFORNIA',
  Colorado = 'COLORADO',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  /** District of Columbia */
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Hawaii = 'HAWAII',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Maine = 'MAINE',
  Manitoba = 'MANITOBA',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  /** Newfoundland And Labrador */
  NewfoundlandAndLabrador = 'NEWFOUNDLAND_AND_LABRADOR',
  /** New Brunswick */
  NewBrunswick = 'NEW_BRUNSWICK',
  /** New Hampshire */
  NewHampshire = 'NEW_HAMPSHIRE',
  /** New Jersey */
  NewJersey = 'NEW_JERSEY',
  /** New Mexico */
  NewMexico = 'NEW_MEXICO',
  /** New York */
  NewYork = 'NEW_YORK',
  /** Northwest Territories */
  NorthwestTerritories = 'NORTHWEST_TERRITORIES',
  /** North Carolina */
  NorthCarolina = 'NORTH_CAROLINA',
  /** North Dakota */
  NorthDakota = 'NORTH_DAKOTA',
  /** Nova Scotia */
  NovaScotia = 'NOVA_SCOTIA',
  Nunavut = 'NUNAVUT',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Ontario = 'ONTARIO',
  Oregon = 'OREGON',
  Pennsylvania = 'PENNSYLVANIA',
  /** Prince Edward Island */
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  Quebec = 'QUEBEC',
  /** Rhode Island */
  RhodeIsland = 'RHODE_ISLAND',
  Saskatchewan = 'SASKATCHEWAN',
  /** South Carolina */
  SouthCarolina = 'SOUTH_CAROLINA',
  /** South Dakota */
  SouthDakota = 'SOUTH_DAKOTA',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  Unknown = 'UNKNOWN',
  Utah = 'UTAH',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  /** West Virginia */
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
  Yukon = 'YUKON'
}

export interface StringStringKeyValuePairGraphType {
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface TestingMutations {
  closeLoanApplication?: Maybe<Scalars['Boolean']>;
  forceApproveApplication?: Maybe<Scalars['Boolean']>;
  startTest?: Maybe<Scalars['Boolean']>;
  stopTest?: Maybe<Scalars['Boolean']>;
  updateVisitorLastName?: Maybe<Scalars['Boolean']>;
}


export interface TestingMutationsCloseLoanApplicationArgs {
  visitorKey?: InputMaybe<Scalars['Int']>;
}


export interface TestingMutationsForceApproveApplicationArgs {
  loanApplicationKey?: InputMaybe<Scalars['Int']>;
}


export interface TestingMutationsStartTestArgs {
  testName?: InputMaybe<Scalars['String']>;
}


export interface TestingMutationsUpdateVisitorLastNameArgs {
  testLastName?: InputMaybe<Scalars['String']>;
  visitorKey?: InputMaybe<Scalars['Int']>;
}

export interface TestingQueries {
  findLoan: Scalars['Int'];
  findVisitors?: Maybe<Array<Maybe<Scalars['Int']>>>;
  getActivities?: Maybe<ActivityData>;
  getAddress?: Maybe<MockDataInputModel>;
  getBankInstitutionNumber?: Maybe<MockDataInputModel>;
  getBankTransitNumber?: Maybe<MockDataInputModel>;
  getBirthDate?: Maybe<MockDataInputModel>;
  getCustomerInitials?: Maybe<MockDataInputModel>;
  getEmail?: Maybe<MockDataInputModel>;
  getEmailVerificationCode?: Maybe<MockDataInputModel>;
  getEmployerIdentificationNumber?: Maybe<MockDataInputModel>;
  getFirstName?: Maybe<MockDataInputModel>;
  getLastName?: Maybe<MockDataInputModel>;
  getLogs?: Maybe<LogData>;
  getPassword?: Maybe<MockDataInputModel>;
  getPhone?: Maybe<MockDataInputModel>;
  getRandomNumber?: Maybe<MockDataInputModel>;
  getRandomString?: Maybe<MockDataInputModel>;
  getSocialSecurityNumber?: Maybe<MockDataInputModel>;
  getVehicleIdentificationNumber?: Maybe<MockDataInputModel>;
  getVisitorEmail?: Maybe<MockDataInputModel>;
  session?: Maybe<Array<Maybe<StringStringKeyValuePairGraphType>>>;
}


export interface TestingQueriesFindLoanArgs {
  criteria?: InputMaybe<SearchCriteria>;
}


export interface TestingQueriesFindVisitorsArgs {
  criteria?: InputMaybe<SearchCriteria>;
}


export interface TestingQueriesGetAddressArgs {
  state?: InputMaybe<State>;
}


export interface TestingQueriesGetRandomNumberArgs {
  maxValue?: InputMaybe<Scalars['Int']>;
  minValue?: InputMaybe<Scalars['Int']>;
}


export interface TestingQueriesGetRandomStringArgs {
  alphaOnly?: InputMaybe<Scalars['Boolean']>;
  length?: InputMaybe<Scalars['Int']>;
}

export type GetAddressQueryVariables = Exact<{
  state?: InputMaybe<State>;
}>;


export type GetAddressQuery = { getAddress?: { address?: { address?: string, city?: string, state?: string, zip?: string } } };

export type GetBankInstitutionNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankInstitutionNumberQuery = { getBankInstitutionNumber?: { bankInstitutionNumber?: string } };

export type GetBankTransitNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankTransitNumberQuery = { getBankTransitNumber?: { bankTransitNumber?: string } };

export type GetBirthDateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBirthDateQuery = { getBirthDate?: { birthDate: Date } };

export type GetCustomerInitialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerInitialsQuery = { getCustomerInitials?: { customerInitials?: string } };

export type GetEmailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailQuery = { getEmail?: { email?: string } };

export type GetEmailVerificationCodeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailVerificationCodeQuery = { getEmailVerificationCode?: { emailVerificationCode?: string } };

export type GetEmployerIdentificationNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmployerIdentificationNumberQuery = { getEmployerIdentificationNumber?: { employerIdentificationNumber?: string } };

export type GetFirstNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFirstNameQuery = { getFirstName?: { firstName?: string } };

export type GetLastNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastNameQuery = { getLastName?: { lastName?: string } };

export type GetPasswordQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPasswordQuery = { getPassword?: { password?: string } };

export type GetPhoneQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhoneQuery = { getPhone?: { phone?: string } };

export type GetRandomNumberQueryVariables = Exact<{
  minValue: Scalars['Int'];
  maxValue: Scalars['Int'];
}>;


export type GetRandomNumberQuery = { getRandomNumber?: { randomNumber?: number } };

export type GetRandomStringQueryVariables = Exact<{
  length: Scalars['Int'];
  alphaOnly: Scalars['Boolean'];
}>;


export type GetRandomStringQuery = { getRandomString?: { randomString?: string } };

export type GetSocialSecurityNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSocialSecurityNumberQuery = { getSocialSecurityNumber?: { socialSecurityNumber?: string } };

export type GetVehicleIdentificationNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVehicleIdentificationNumberQuery = { getVehicleIdentificationNumber?: { vehicleIdentificationNumber?: string } };

export type GetVisitorEmailQueryVariables = Exact<{ [key: string]: never; }>;


export type GetVisitorEmailQuery = { getVisitorEmail?: { visitorEmail?: string } };

export type GetSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionQuery = { session?: Array<{ key: string, value: string }> };

export type StartTestMutationVariables = Exact<{
  testName: Scalars['String'];
}>;


export type StartTestMutation = { startTest?: boolean };

export type StopTestMutationVariables = Exact<{ [key: string]: never; }>;


export type StopTestMutation = { stopTest?: boolean };

export type GetLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLogsQuery = { getLogs?: { url?: string, entries?: Array<{ enteredAt: Date, categoryName?: string, message?: string, logLevel: LogLevel, exception?: string, scopes?: Array<{ key?: string, value?: string }> }> } };

export type GetActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActivitiesQuery = { getActivities?: { url?: string, entries?: Array<{ spanId?: string, startedAt: Date, description?: string, duration: number, size?: number }> } };

export type ForceApproveApplicationMutationVariables = Exact<{
  loanApplicationKey?: InputMaybe<Scalars['Int']>;
}>;


export type ForceApproveApplicationMutation = { forceApproveApplication?: boolean };

export type FindVisitorsQueryVariables = Exact<{
  criteria: SearchCriteria;
}>;


export type FindVisitorsQuery = { findVisitors?: Array<number> };

export type FindLoanQueryVariables = Exact<{
  criteria: SearchCriteria;
}>;


export type FindLoanQuery = { findLoan: number };

export type UpdateVisitorLastNameMutationVariables = Exact<{
  visitorKey: Scalars['Int'];
  testLastName: Scalars['String'];
}>;


export type UpdateVisitorLastNameMutation = { updateVisitorLastName?: boolean };

export type CloseLoanApplicationMutationVariables = Exact<{
  visitorKey: Scalars['Int'];
}>;


export type CloseLoanApplicationMutation = { closeLoanApplication?: boolean };


export class TestingClient extends BaseClient {
  path = '/graphql/Testing';
  

      public getAddress(state: GetAddressQueryVariables['state'], signal?: AbortSignal | RequestInit) {
          return this.request<GetAddressQuery>(`query GetAddress($state: State) {
    getAddress(state: $state) {
        address {
            address
            city
            state
            zip
        }
    }
}`, { state }, createRequestInit(signal), 'GetAddress'); 
      }
  

      public getBankInstitutionNumber(signal?: AbortSignal | RequestInit) {
          return this.request<GetBankInstitutionNumberQuery>(`query GetBankInstitutionNumber {
    getBankInstitutionNumber {
        bankInstitutionNumber
    }
}`, {}, createRequestInit(signal), 'GetBankInstitutionNumber'); 
      }
  

      public getBankTransitNumber(signal?: AbortSignal | RequestInit) {
          return this.request<GetBankTransitNumberQuery>(`query GetBankTransitNumber {
    getBankTransitNumber {
        bankTransitNumber
    }
}`, {}, createRequestInit(signal), 'GetBankTransitNumber'); 
      }
  

      public getBirthDate(signal?: AbortSignal | RequestInit) {
          return this.request<GetBirthDateQuery>(`query GetBirthDate {
    getBirthDate {
        birthDate
    }
}`, {}, createRequestInit(signal), 'GetBirthDate'); 
      }
  

      public getCustomerInitials(signal?: AbortSignal | RequestInit) {
          return this.request<GetCustomerInitialsQuery>(`query GetCustomerInitials {
    getCustomerInitials {
        customerInitials
    }
}`, {}, createRequestInit(signal), 'GetCustomerInitials'); 
      }
  

      public getEmail(signal?: AbortSignal | RequestInit) {
          return this.request<GetEmailQuery>(`query GetEmail {
    getEmail {
        email
    }
}`, {}, createRequestInit(signal), 'GetEmail'); 
      }
  

      public getEmailVerificationCode(signal?: AbortSignal | RequestInit) {
          return this.request<GetEmailVerificationCodeQuery>(`query GetEmailVerificationCode {
    getEmailVerificationCode {
        emailVerificationCode
    }
}`, {}, createRequestInit(signal), 'GetEmailVerificationCode'); 
      }
  

      public getEmployerIdentificationNumber(signal?: AbortSignal | RequestInit) {
          return this.request<GetEmployerIdentificationNumberQuery>(`query GetEmployerIdentificationNumber {
    getEmployerIdentificationNumber {
        employerIdentificationNumber
    }
}`, {}, createRequestInit(signal), 'GetEmployerIdentificationNumber'); 
      }
  

      public getFirstName(signal?: AbortSignal | RequestInit) {
          return this.request<GetFirstNameQuery>(`query GetFirstName {
    getFirstName {
        firstName
    }
}`, {}, createRequestInit(signal), 'GetFirstName'); 
      }
  

      public getLastName(signal?: AbortSignal | RequestInit) {
          return this.request<GetLastNameQuery>(`query GetLastName {
    getLastName {
        lastName
    }
}`, {}, createRequestInit(signal), 'GetLastName'); 
      }
  

      public getPassword(signal?: AbortSignal | RequestInit) {
          return this.request<GetPasswordQuery>(`query GetPassword {
    getPassword {
        password
    }
}`, {}, createRequestInit(signal), 'GetPassword'); 
      }
  

      public getPhone(signal?: AbortSignal | RequestInit) {
          return this.request<GetPhoneQuery>(`query GetPhone {
    getPhone {
        phone
    }
}`, {}, createRequestInit(signal), 'GetPhone'); 
      }
  

      public getRandomNumber(minValue: GetRandomNumberQueryVariables['minValue'], maxValue: GetRandomNumberQueryVariables['maxValue'], signal?: AbortSignal | RequestInit) {
          return this.request<GetRandomNumberQuery>(`query GetRandomNumber($minValue: Int!, $maxValue: Int!) {
    getRandomNumber(minValue: $minValue, maxValue: $maxValue) {
        randomNumber
    }
}`, { minValue,maxValue }, createRequestInit(signal), 'GetRandomNumber'); 
      }
  

      public getRandomString(length: GetRandomStringQueryVariables['length'], alphaOnly: GetRandomStringQueryVariables['alphaOnly'], signal?: AbortSignal | RequestInit) {
          return this.request<GetRandomStringQuery>(`query GetRandomString($length: Int!, $alphaOnly: Boolean!) {
    getRandomString(length: $length, alphaOnly: $alphaOnly) {
        randomString
    }
}`, { length,alphaOnly }, createRequestInit(signal), 'GetRandomString'); 
      }
  

      public getSocialSecurityNumber(signal?: AbortSignal | RequestInit) {
          return this.request<GetSocialSecurityNumberQuery>(`query GetSocialSecurityNumber {
    getSocialSecurityNumber {
        socialSecurityNumber
    }
}`, {}, createRequestInit(signal), 'GetSocialSecurityNumber'); 
      }
  

      public getVehicleIdentificationNumber(signal?: AbortSignal | RequestInit) {
          return this.request<GetVehicleIdentificationNumberQuery>(`query GetVehicleIdentificationNumber {
    getVehicleIdentificationNumber {
        vehicleIdentificationNumber
    }
}`, {}, createRequestInit(signal), 'GetVehicleIdentificationNumber'); 
      }
  

      public getVisitorEmail(signal?: AbortSignal | RequestInit) {
          return this.request<GetVisitorEmailQuery>(`query GetVisitorEmail {
    getVisitorEmail {
        visitorEmail
    }
}`, {}, createRequestInit(signal), 'GetVisitorEmail'); 
      }
  

      public startTest(testName: StartTestMutationVariables['testName'], signal?: AbortSignal | RequestInit) {
          return this.request<StartTestMutation>(`mutation StartTest($testName: String!) {
    startTest(testName: $testName)
}`, { testName }, createRequestInit(signal), 'StartTest');
      }
  

      public stopTest(signal?: AbortSignal | RequestInit) {
          return this.request<StopTestMutation>(`mutation StopTest {
    stopTest
}`, {}, createRequestInit(signal), 'StopTest');
      }
  

      public forceApproveApplication(loanApplicationKey: ForceApproveApplicationMutationVariables['loanApplicationKey'], signal?: AbortSignal | RequestInit) {
          return this.request<ForceApproveApplicationMutation>(`mutation ForceApproveApplication($loanApplicationKey: Int) {
    forceApproveApplication(loanApplicationKey: $loanApplicationKey)
}`, { loanApplicationKey }, createRequestInit(signal), 'ForceApproveApplication');
      }
  

      public getSession(signal?: AbortSignal | RequestInit) {
          return this.request<GetSessionQuery>(`query GetSession {
    session {
        key
        value
    }
}`, {}, createRequestInit(signal), 'GetSession'); 
      }
  

      public getLogs(signal?: AbortSignal | RequestInit) {
          return this.request<GetLogsQuery>(`query GetLogs {
    getLogs {
        url
        entries {
            enteredAt
            categoryName
            message
            logLevel
            exception
            scopes {
                key
                value
            }
        }
    }
}`, {}, createRequestInit(signal), 'GetLogs'); 
      }
  

      public getActivities(signal?: AbortSignal | RequestInit) {
          return this.request<GetActivitiesQuery>(`query GetActivities {
    getActivities {
        url
        entries {
            spanId
            startedAt
            description
            duration
            size
        }
    }
}`, {}, createRequestInit(signal), 'GetActivities'); 
      }
  

      public updateVisitorLastName(visitorKey: UpdateVisitorLastNameMutationVariables['visitorKey'], testLastName: UpdateVisitorLastNameMutationVariables['testLastName'], signal?: AbortSignal | RequestInit) {
          return this.request<UpdateVisitorLastNameMutation>(`mutation UpdateVisitorLastName($visitorKey: Int!, $testLastName: String!) {
    updateVisitorLastName(visitorKey: $visitorKey, testLastName: $testLastName)
}`, { visitorKey,testLastName }, createRequestInit(signal), 'UpdateVisitorLastName');
      }
  

      public closeLoanApplication(visitorKey: CloseLoanApplicationMutationVariables['visitorKey'], signal?: AbortSignal | RequestInit) {
          return this.request<CloseLoanApplicationMutation>(`mutation CloseLoanApplication($visitorKey: Int!) {
    closeLoanApplication(visitorKey: $visitorKey)
}`, { visitorKey }, createRequestInit(signal), 'CloseLoanApplication');
      }
  

      public findVisitors(criteria: FindVisitorsQueryVariables['criteria'], signal?: AbortSignal | RequestInit) {
          return this.request<FindVisitorsQuery>(`query FindVisitors($criteria: SearchCriteria!) {
    findVisitors(criteria: $criteria)
}`, { criteria }, createRequestInit(signal), 'FindVisitors'); 
      }
  

      public findLoan(criteria: FindLoanQueryVariables['criteria'], signal?: AbortSignal | RequestInit) {
          return this.request<FindLoanQuery>(`query FindLoan($criteria: SearchCriteria!) {
    findLoan(criteria: $criteria)
}`, { criteria }, createRequestInit(signal), 'FindLoan'); 
      }
  
}